export const starters = {
"CRM": [
    {
    "title": "CRM for Artists",
    "description": "Create a CRM for artists - should be tailored for visual artists to manage artworks, exhibitions, and clients. I want features to include saving images of sold artwork, custom fields for medium and price, inventory management for available pieces, exhibition schedules, and segmentation of client profiles."
    },
    {
    "title": "CRM for Real Estate Agents",
    "description": "Design a CRM for real estate agents - should manage property listings and client interactions. Include features like property images and descriptions, automatic reminders for contract renewals and viewings, integration with property portals, commission tracking, and geolocation mapping for properties."
    },
    {
    "title": "CRM for Lawyers/Legal Practices",
    "description": "Develop a CRM for law firms - should help manage cases, track billable hours, and store legal documents. Include features for case management, time tracking, legal workflows, team task delegation, client profiles with case history, and court date reminders."
    },
    {
    "title": "CRM for Photographers",
    "description": "Build a CRM for photographers - should handle client galleries and bookings. Include features for gallery management, photoshoot booking with reminders, file delivery systems, pricing packages, and client referral tracking for expanding the business."
    },
    {
    "title": "CRM for Healthcare Practices",
    "description": "Create a CRM for healthcare providers - should support clinics with patient management and compliance. Include features for medical history tracking, HIPAA-compliant communications, prescription tracking, billing integration with insurance claims, and patient satisfaction surveys."
    },
    {
    "title": "CRM for Influencers/Content Creators",
    "description": "Design a CRM for influencers - should help manage partnerships and content. Include features for campaign management, brand partnership tracking, content scheduling, revenue tracking from posts and merchandise, and audience segmentation by platform."
    },
    {
    "title": "CRM for Wedding Planners",
    "description": "Develop a CRM for wedding planners - should help manage tasks, budgets, and events. Include features for task management, client profiles segmented by couples and vendors, budget tracking, event timelines, and visual planning tools like seating arrangements."
    },
    {
    "title": "CRM for Fitness Trainers/Gyms",
    "description": "Build a CRM for fitness professionals - should track client progress and manage schedules. Include features for client progress tracking, integration with wearable devices, personalized workout plans, session reminders, and social tracking for referrals."
    },
    {
    "title": "CRM for Musicians/Bands",
    "description": "Create a CRM for musicians and bands - should handle events and fan interactions. Include features for event management, fan segmentation, music catalog management, collaboration tools, and tracking of streaming revenue and merchandise sales."
    },
    {
    "title": "CRM for Construction Contractors",
    "description": "Design a CRM for contractors - should manage projects, timelines, and resources. Include features for project milestones, resource tracking for materials and subcontractors, budget management, document storage for plans and permits, and client updates on project progress."
    }
],
"Dev productivity": [
    {
    "title": "Online JSON Viewer",
    "description": "Create an online JSON viewer - should allow users to view and format JSON data. Include features for syntax highlighting, collapsible tree structure, JSON validation, and the ability to upload or paste raw JSON for easy analysis."
    },
    {
    "title": "Markdown to HTML Converter",
    "description": "Develop a Markdown to HTML converter - should quickly convert Markdown text into HTML. Include features for live preview, support for common Markdown syntax (headings, links, code blocks), and export options to download the resulting HTML."
    },
    {
    "title": "Regex Tester",
    "description": "Build a regex tester tool - should help users test and refine regular expressions. Include features for real-time regex evaluation, examples, syntax highlighting, error notifications, and a library of commonly used regex patterns."
    },
    {
    "title": "Code Diff Checker",
    "description": "Create a code diff checker - should compare two code snippets side by side to highlight differences. Include features for line-by-line comparison, syntax highlighting for multiple programming languages, and exportable diff reports."
    },
    {
    "title": "REST API Tester",
    "description": "Design a REST API tester - should allow testing of API endpoints with customizable HTTP requests. Include features to set headers, body, and query parameters, store test cases, and view response details like headers and status codes."
    },
    {
    "title": "Cron Expression Generator",
    "description": "Develop a cron expression generator - should help create cron job expressions for scheduling tasks. Include an intuitive UI to select time intervals, a preview of the generated cron expression, and descriptions of each component of the expression."
    },
    {
    "title": "Base64 Encoder/Decoder",
    "description": "Build a Base64 encoder/decoder - should encode or decode text into Base64 format. Include support for large text blocks, file encoding/decoding, and one-click conversion between Base64 and plain text."
    },
    {
    "title": "UUID Generator",
    "description": "Create a UUID generator - should generate Universally Unique Identifiers (UUIDs) for use in applications. Include options for different UUID versions, batch generation, and the ability to copy or export UUIDs in bulk."
    },
    {
    "title": "Color Palette Generator",
    "description": "Design a color palette generator - should create and visualize color palettes for web design. Include hex and RGB code conversion, color harmonies (complementary, triadic), and the ability to save and export color schemes."
    }
],
"Educational": [
    {
    "title": "Interactive Flashcard Creator",
    "description": "Develop an interactive flashcard creator - should allow users to create and study digital flashcards with spaced repetition algorithms for efficient learning."
    },
    {
    "title": "Quiz Generator",
    "description": "Build a quiz generator - should create quizzes from custom content or pre-existing question banks for various subjects."
    },
    {
    "title": "Language Learning Games",
    "description": "Design language learning games - should be fun and interactive, aimed at improving vocabulary, grammar, and pronunciation in foreign languages."
    },
    {
    "title": "Math Problem Solver",
    "description": "Create a math problem solver - should provide step-by-step solutions with explanations for various difficulty levels of math problems."
    },
    {
    "title": "Historical Timeline Maker",
    "description": "Develop a historical timeline maker - should allow users to create visual timelines of historical events with customizable layouts and multimedia integration."
    }
],
"Content Generation and Editing": [
    {
    "title": "AI-Powered Writing Assistant",
    "description": "Build an AI-powered writing assistant - should help improve writing with suggestions for grammar, style, and clarity using AI algorithms."
    },
    {
    "title": "Social Media Post Generator",
    "description": "Create a social media post generator - should produce engaging posts with customizable templates and trending hashtags."
    },
    {
    "title": "Meme Creator",
    "description": "Design an easy-to-use meme creator - should include a library of popular templates and a custom image upload option."
    },
    {
    "title": "Infographic Maker",
    "description": "Develop an infographic maker - should be a design tool for creating visually appealing infographics with pre-made templates and icons."
    },
    {
    "title": "Resume Builder",
    "description": "Build a resume builder - should craft professional resumes with industry-specific templates and content suggestions."
    }
],
"Personal Finance": [
    {
    "title": "Budget Planner",
    "description": "Create a budget planner - should help users create and manage personal or household budgets with customizable categories."
    },
    {
    "title": "Expense Tracker",
    "description": "Develop an expense tracker - should log and categorize expenses, providing insights and reports on spending habits."
    },
    {
    "title": "Investment Calculator",
    "description": "Build an investment calculator - should calculate potential returns on investments based on various parameters and market scenarios."
    },
    {
    "title": "Debt Repayment Planner",
    "description": "Design a debt repayment planner - should create strategies for efficient debt repayment, including snowball and avalanche methods."
    },
    {
    "title": "Currency Converter",
    "description": "Create a currency converter - should provide real-time currency conversion with historical rate charts and rate alerts."
    }
],
"Health and Wellness": [
    {
    "title": "Meal Planner and Recipe Finder",
    "description": "Develop a meal planner and recipe finder - should plan balanced meals and suggest recipes based on dietary preferences and restrictions."
    },
    {
    "title": "Workout Routine Generator",
    "description": "Build a workout routine generator - should create personalized workout plans based on fitness goals, equipment availability, and time constraints."
    },
    {
    "title": "Meditation Timer and Guide",
    "description": "Create a meditation timer and guide - should offer guided meditations and customizable meditation timers with ambient sounds."
    },
    {
    "title": "Sleep Cycle Analyzer",
    "description": "Design a sleep cycle analyzer - should track sleep patterns and provide insights for improving sleep quality."
    },
    {
    "title": "Water Intake Tracker",
    "description": "Develop a water intake tracker - should monitor daily water consumption and send reminders to stay hydrated."
    }
],
"Productivity": [
    {
    "title": "Pomodoro Timer",
    "description": "Create a Pomodoro timer - should implement the Pomodoro Technique with customizable work and break intervals."
    },
    {
    "title": "To-Do List Organizer",
    "description": "Build a to-do list organizer - should manage tasks with priority levels, due dates, and collaboration features."
    },
    {
    "title": "Mind Mapping Tool",
    "description": "Design a mind mapping tool - should create visual mind maps for brainstorming and organizing ideas."
    },
    {
    "title": "Goal Tracker",
    "description": "Develop a goal tracker - should help set and track personal or professional goals with progress visualization."
    },
    {
    "title": "Habit Formation Assistant",
    "description": "Create a habit formation assistant - should help users build and maintain positive habits through reminders and streak tracking."
    }
],
"Travel Planning": [
    {
    "title": "Itinerary Builder",
    "description": "Build an itinerary builder - should create detailed travel itineraries with attractions, reservations, and transportation details."
    },
    {
    "title": "Packing List Generator",
    "description": "Develop a packing list generator - should generate customized packing lists based on destination, duration, and activities."
    },
    {
    "title": "Flight Delay Predictor",
    "description": "Create a flight delay predictor - should estimate the likelihood of flight delays based on historical data and current conditions."
    },
    {
    "title": "Local Currency Converter",
    "description": "Design a local currency converter - should convert currencies with offline support for travelers without constant internet access."
    },
    {
    "title": "Travel Phrase Translator",
    "description": "Build a travel phrase translator - should translate common travel phrases with audio pronunciation guides."
    }
],
"Entertainment": [
    {
    "title": "Movie Recommendation Engine",
    "description": "Create a movie recommendation engine - should suggest movies based on user preferences, ratings, and viewing history."
    },
    {
    "title": "Book Discovery Tool",
    "description": "Develop a book discovery tool - should recommend books across genres based on reading preferences and past favorites."
    },
    {
    "title": "Podcast Finder",
    "description": "Build a podcast finder - should discover new podcasts based on topics of interest and listening habits."
    },
    {
    "title": "TV Show Episode Tracker",
    "description": "Design a TV show episode tracker - should keep track of watched episodes and provide notifications for new releases."
    },
    {
    "title": "Random Activity Generator",
    "description": "Create a random activity generator - should suggest random activities or hobbies to try when bored or looking for inspiration."
    }
],
"Environmental": [
    {
    "title": "Carbon Footprint Calculator",
    "description": "Develop a carbon footprint calculator - should estimate personal or household carbon footprint and suggest reduction strategies."
    },
    {
    "title": "Recycling Guide",
    "description": "Build a recycling guide - should provide location-specific information on how to properly recycle various items."
    },
    {
    "title": "Sustainable Product Finder",
    "description": "Create a sustainable product finder - should help users find eco-friendly alternatives to common products."
    },
    {
    "title": "Energy Usage Monitor",
    "description": "Design an energy usage monitor - should track and analyze home energy consumption, offering tips for reduction."
    },
    {
    "title": "Local Eco-Event Finder",
    "description": "Develop a local eco-event finder - should locate and promote environmental events and volunteer opportunities in the user's area."
    }
],
"Home Management": [
    {
    "title": "Home Inventory Tracker",
    "description": "Create a home inventory tracker - should catalog household items for insurance purposes or organization."
    },
    {
    "title": "Chore Scheduler",
    "description": "Build a chore scheduler - should assign and rotate household chores among family members or roommates."
    },
    {
    "title": "Plant Care Reminder",
    "description": "Design a plant care reminder - should send reminders for watering and caring for house plants based on species and season."
    },
    {
    "title": "DIY Project Planner",
    "description": "Develop a DIY project planner - should help plan and track home improvement projects with material lists and tutorials."
    },
    {
    "title": "Smart Home Device Dashboard",
    "description": "Create a smart home device dashboard - should centralize control and monitoring of various smart home devices."
    }
],
"Creative Tools": [
    {
    "title": "Color Scheme Generator",
    "description": "Build a color scheme generator - should create harmonious color palettes for design projects with export options."
    },
    {
    "title": "Font Pairing Assistant",
    "description": "Design a font pairing assistant - should suggest complementary font combinations for typography projects."
    },
    {
    "title": "Logo Maker",
    "description": "Create a logo maker - should design simple logos using pre-made elements and customization options."
    },
    {
    "title": "Music Chord Progression Generator",
    "description": "Develop a music chord progression generator - should create chord progressions for songwriting inspiration across various genres."
    },
    {
    "title": "Digital Art Sketchpad",
    "description": "Build a digital art sketchpad - should provide a simple drawing interface with basic tools for digital sketching and doodling."
    }
]
}