import { useApp } from "../../../context/AppContext";
import { ArrowDown, Trash2, Plus, User, Cpu } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "@/components/ui/dialog";
import { useState } from "react";
import EditInPlace from "@/components/EditInPlace";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";

export default function AppProductFlows() {
    const { app, updateApp, setApp, addChatMessage } = useApp();
    const [flowToDelete, setFlowToDelete] = useState(null);
    const [selectedFlow, setSelectedFlow] = useState(0);

    const handleDeleteFlow = (flowIndex) => {
        const newFlows = [...app.product_flows];
        newFlows.splice(flowIndex, 1);
        updateApp({
            product_flows: newFlows
        });
        setFlowToDelete(null);
        if (selectedFlow >= newFlows.length) {
            setSelectedFlow(Math.max(0, newFlows.length - 1));
        }
    };

    const handleStepContentChange = (flowIndex, stepIndex, newContent) => {
        const newFlows = [...app.product_flows];
        newFlows[flowIndex].steps[stepIndex].content = newContent;
        updateApp({
            product_flows: newFlows
        });
    };

    const handleDeleteStep = (flowIndex, stepIndex) => {
        const newFlows = [...app.product_flows];
        newFlows[flowIndex].steps.splice(stepIndex, 1);
        updateApp({
            product_flows: newFlows
        });
    };

    const handleInitiatorChange = (flowIndex, stepIndex, newInitiator) => {
        const newFlows = [...app.product_flows];
        newFlows[flowIndex].steps[stepIndex].initiator = newInitiator;
        updateApp({
            product_flows: newFlows
        });
    };

    const handleAddStep = (flowIndex, stepIndex) => {
        const newFlows = [...app.product_flows];
        const newStep = {
            content: "New step",
            initiator: "user"
        };
        newFlows[flowIndex].steps.splice(stepIndex + 1, 0, newStep);
        app.product_flows = newFlows;
        setApp(app);
        updateApp({
            product_flows: newFlows
        });
    };

    return (
        <div className="container mx-auto p-6  lg:max-w-4xl">
            <div className="flex justify-between items-center mb-8">
                <div className="space-y-2">
                    <h1 className="text-4xl font-bold tracking-tight">Product Flows</h1>
                    <p className="text-lg text-muted-foreground">
                        Define the interaction flows between users and your product
                    </p>
                </div>
                {app.app_stage !== 'ready' && <Button 
                    size="default"
                    disabled={app.status?.state === "processing"}
                    onClick={() => addChatMessage({content: "proceed with app creation"})}>
                    Create App
                </Button>}
            </div>

            <div className="flex flex-col gap-8 md:flex-row mt-8">
                <nav className="md:w-1/4">
                    <div className="space-y-1">
                        {app.product_flows?.map((flow, index) => (
                            <Button
                                key={index}
                                variant={selectedFlow === index ? "secondary" : "ghost"}
                                className="w-full justify-start"
                                onClick={() => setSelectedFlow(index)}
                            >
                                {flow.title}
                            </Button>
                        ))}
                    </div>
                </nav>

                <main className="flex-1">
                    {app.product_flows?.[selectedFlow] && (
                        <div className="space-y-6">
                            <div className="flex justify-between items-center">
                                <h3 className="text-xl font-semibold text-gray-900">{app.product_flows[selectedFlow].title}</h3>
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    onClick={() => setFlowToDelete(selectedFlow)}
                                    className="text-gray-400 hover:text-red-600 transition-colors duration-200"
                                >
                                    <Trash2 className="h-4 w-4" />
                                </Button>
                            </div>
                            
                            <div className="space-y-3">
                                {app.product_flows[selectedFlow].steps?.map((step, stepIndex) => (
                                    <div key={stepIndex.toString() + '-' + step.content}>
                                        <div 
                                            className="flex items-center gap-4 p-4 rounded-lg bg-white border border-gray-200 hover:border-gray-300 shadow-sm transition-all duration-200 group"
                                        >
                                            <div className={`p-2 rounded-full ${
                                                step.initiator === 'user' 
                                                    ? 'bg-green-100 text-green-600'
                                                    : 'bg-purple-100 text-purple-600'
                                            }`}>
                                                {step.initiator === 'user' ? <User className="h-4 w-4" /> : <Cpu className="h-4 w-4" />}
                                            </div>
                                            <div className="flex-1">
                                                <div className="text-sm text-gray-700">
                                                    <EditInPlace
                                                        initialText={step.content}
                                                        onChange={(newContent) => handleStepContentChange(selectedFlow, stepIndex, newContent)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex items-center gap-3">
                                                <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex items-center gap-3">
                                                    <Select
                                                        value={step.initiator}
                                                        onValueChange={(value) => handleInitiatorChange(selectedFlow, stepIndex, value)}
                                                    >
                                                        <SelectTrigger className="w-[140px] h-9 text-sm">
                                                            <SelectValue />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectItem value="user" className="text-sm py-1.5">User action</SelectItem>
                                                            <SelectItem value="system" className="text-sm py-1.5">System action</SelectItem>
                                                        </SelectContent>
                                                    </Select>
                                                    <Button
                                                        variant="ghost"
                                                        size="icon"
                                                        onClick={() => handleDeleteStep(selectedFlow, stepIndex)}
                                                        className="text-red-500 hover:text-red-700 hover:bg-red-100 h-9 w-9"
                                                    >
                                                        <Trash2 className="h-4 w-4" />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative group">
                                            <div className="flex justify-center my-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                                <Button
                                                    variant="ghost"
                                                    size="sm"
                                                    onClick={() => handleAddStep(selectedFlow, stepIndex)}
                                                    className="rounded-full h-8 w-8 p-0 bg-white border border-gray-200 hover:bg-gray-50 hover:border-gray-300 transition-all duration-200 z-10"
                                                >
                                                    <Plus className="h-4 w-4 text-gray-600" />
                                                </Button>
                                            </div>
                                            {stepIndex < app.product_flows[selectedFlow].steps.length - 1 && (
                                                <ArrowDown className="absolute left-1/2 top-1 -translate-x-1/2 h-5 w-5 text-gray-400 group-hover:opacity-0 transition-opacity duration-200" />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </main>
            </div>

            <Dialog open={flowToDelete !== null} onOpenChange={() => setFlowToDelete(null)}>
                <DialogContent className="sm:max-w-md">
                    <DialogHeader>
                        <DialogTitle className="text-xl font-semibold">Delete Product Flow</DialogTitle>
                        <DialogDescription className="text-gray-600">
                            Are you sure you want to delete this product flow? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="gap-3 sm:gap-0">
                        <Button variant="outline" onClick={() => setFlowToDelete(null)}>
                            Cancel
                        </Button>
                        <Button variant="destructive" onClick={() => handleDeleteFlow(flowToDelete)}>
                            Delete
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
}