import Home from "./home/HomePage";
import UserSettings from "./UserSettings";
import UserApps from "./UserApps";
import AppUserFacingChat from "./AppUserFacingChat";
import { useParams, useNavigate } from "react-router-dom";
import { Home as HomeIcon, Edit, Settings, Code, MessageSquare, Layers, Plug2, Plus } from 'lucide-react';
import UserAppEditor from "./app-editor/UserAppEditor";
import AppDemo from "../components/user-app/demo/AppDemo";
import { AppProvider } from "../context/AppContext";
import IntegrationsPage from "./IntegrationsPage";
import CreateAppPage from "./CreateApp";

function AppDemoWrapper() {
    const navigate = useNavigate();
    const {appId} = useParams();

    const setPage = (pageName, urlParams) => {
        navigate(`/apps/${appId}/show/${pageName}?${new URLSearchParams(urlParams).toString()}`);
    }


    return <AppProvider>
        <div className="min-h-screen w-full relative">
            <AppDemo setPage={setPage} />
        </div>
    </AppProvider>;
}

function ProxyPageComponent({urlChangeHandler}) {
    const currentUrl = window.location.pathname;
    const newUrl = urlChangeHandler(currentUrl);
    console.log("ProxyPageComponent", currentUrl, newUrl);
    if (newUrl !== currentUrl) {
        window.location.href = newUrl;
    }
    return <div>Loading...</div>;
}

const pages = [
    { 
        name: 'Home', 
        url: '/', 
        component: <Home />, 
        icon: <HomeIcon /> 
    },
    {
        name: 'User Apps', 
        url: '/user-apps/:appId/show/:pageName?', 
        component: <ProxyPageComponent urlChangeHandler={(url) => url.replace('/user-apps/', '/apps/')} />, 
        icon: <Layers />,
        showInMenu: false
    },
    { 
        name: 'User Apps', url: '/apps/', component: <UserApps />, icon: <Layers />,
        children: [
            {
                name: 'User App Dashboard', 
                url: '/apps/:appId', 
                component: <UserAppEditor />, 
                icon: <Layers />,
            },
            { 
                name: 'Show User App', 
                url: '/apps/:appId/show/:pageName?', 
                component: <AppDemoWrapper />, 
                icon: <Code />,
                usesLayout: false
            },
            { 
                name: 'Edit User App', 
                url: '/apps/:appId/editor/preview/:pageName', 
                component: <UserAppEditor view="preview" />, 
                icon: <Edit />,
            },
            { 
                name: 'Edit User App', 
                url: '/apps/:appId/dashboard', 
                component: <UserAppEditor view="workspace" />, 
                icon: <Edit />,
            },
            { 
                name: 'Edit User App', 
                url: '/apps/:appId/editor/preview', 
                component: <UserAppEditor view="preview" />, 
                icon: <Edit />,
            },
            { 
                name: 'Edit User App', 
                url: '/apps/:appId/editor/workspace/:workspaceView', 
                component: <UserAppEditor view="workspace" />, 
                icon: <Edit />,
            },
            {
                name: 'App User Facing Chat',
                url: '/apps/:appId/chat',
                component: <AppUserFacingChat />,
                icon: <MessageSquare />,
                usesLayout: false
            },
            {
                name: 'Create App',
                url: '/create-app',
                component: <CreateAppPage />,
                icon: <Plus />,
            }
        ]
    },
    { 
        name: 'User Settings', 
        url: '/user-settings',
        hide: true,
        component: <UserSettings />,
        icon: <Settings /> 
    },
    {
        name: 'Integrations',
        url: '/integrations',
        component: <IntegrationsPage />,
        icon: <Plug2 /> 
    }
]

  
export {
    pages
};