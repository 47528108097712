import React from 'react';
import { Editor } from '@monaco-editor/react';

const CodeEditor = ({ value, onChange, language = 'javascript', readOnly = false, height, className, style, ...props }) => {
  const handleEditorChange = (newValue) => {
    if (onChange && !readOnly) {
      onChange(newValue);
    }
  };

  const additionalProps = {};
  if (height) {
    additionalProps.height = height;
  }

  return (
    <Editor
      defaultLanguage={language}
      value={value}
      onChange={handleEditorChange}
      
      options={{
        minimap: { enabled: false },
        scrollBeyondLastLine: false,
        fontSize: 14,
        readOnly: readOnly,
      }}
      {...additionalProps}
      {...props}
    />
  );
};




const CodeEditorWithHeightWrapper = ({height, ...props}) => {
  return <CodeEditor height={height || '400px'} {...props} />
}


export default CodeEditor;
