import React, { useCallback, useState, useEffect } from 'react'
import { X, Upload } from 'lucide-react'
import { useApp } from "../../../../context/AppContext";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

export default function FileUpload({ value, onChange, isMulti }) {
  const { integrationsSDK } = useApp();
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (value) {
      const initialFiles = isMulti ? value : [value];
      setFiles(initialFiles.map(fileUrl => ({
        name: fileUrl.split('/').pop(),
        url: fileUrl
      })));
    }
  }, [value, isMulti]);

  const onDrop = useCallback(async (acceptedFiles) => {
    setUploading(true);
    try {
      const uploadedFiles = await Promise.all(acceptedFiles.map(async (file) => {
        const { file_url } = await integrationsSDK.Core.UploadFile({ file });
        return { name: file.name, url: file_url };
      }));

      setFiles(prevFiles => {
        const newFiles = isMulti ? [...prevFiles, ...uploadedFiles] : uploadedFiles;
        updateOnChange(newFiles);
        return newFiles;
      });
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setUploading(false);
    }
  }, [isMulti, integrationsSDK, onChange]);

  const removeFile = (fileToRemove) => {
    setFiles(files => {
      const newFiles = files.filter(file => file !== fileToRemove);
      updateOnChange(newFiles);
      return newFiles;
    });
  };

  const updateOnChange = (newFiles) => {
    if (isMulti) {
      onChange(newFiles.map(file => file.url));
    } else {
      onChange(newFiles.length > 0 ? newFiles[0].url : null);
    }
  };

  return (
    <div className="w-full">
      <div
        className={cn(
          "flex items-center justify-center w-full rounded-lg border border-dashed",
          "border-muted-foreground/25 hover:border-primary transition-colors",
          "p-4 cursor-pointer"
        )}
        onClick={() => document.getElementById('fileInput')?.click()}
      >
        {uploading ? (
          <div className="loading loading-spinner"></div>
        ) : (
          <Upload className="h-5 w-5 text-muted-foreground" />
        )}
        <input
          id="fileInput"
          type="file"
          multiple={isMulti}
          className="hidden"
          onChange={(e) => onDrop(Array.from(e.target.files || []))}
          disabled={uploading}
        />
      </div>

      {files.length > 0 && (
        <div className="mt-2 space-y-1">
          {files.map((file, index) => (
            <div key={index} className="flex items-center justify-between bg-muted/50 p-1 rounded">
              <a 
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs hover:underline truncate"
              >
                {file.name}
              </a>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => removeFile(file)}
                className="h-4 w-4 p-0"
              >
                <X className="h-3 w-3" />
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}