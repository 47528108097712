import InstallableIntegrationsAPI from '../api/InstallableIntegrations';
import { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Loader2, Trash2 } from 'lucide-react';
import { Badge } from '../components/ui/badge';
import JsonSchemaForm from '../lib/components/json-schema-form/JsonSchemaForm';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '../components/ui/dialog';
import { toast } from '@/hooks/use-toast';

import * as LucideIcons from 'lucide-react';

window.LucideIcons = LucideIcons;

export default function IntegrationsPage() {
    const [integrations, setIntegrations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [instances, setInstances] = useState([]);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [selectedInstance, setSelectedInstance] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [testing, setTesting] = useState(false);
    const [testingInstanceId, setTestingInstanceId] = useState(null);
    const [currentFormValues, setCurrentFormValues] = useState(null);
    const [instanceToDelete, setInstanceToDelete] = useState(null);

    useEffect(() => {
        async function fetchIntegrations() {
            try {
                const integrationsData = (await InstallableIntegrationsAPI.listIntegrations()).map(
                    integration => ({
                        ...integration,
                        name: integration.title  // for now we use the title as the name
                    })
                );
                setIntegrations(integrationsData);
                const integrationsInstances = await InstallableIntegrationsAPI.list();
                setInstances(integrationsInstances);
            } catch (error) {
                console.error('Failed to fetch integrations:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchIntegrations();
    }, []);

    const handleIntegrationClick = async (integration) => {
        try {
            setSelectedIntegration(integration);
            setSelectedInstance(null);
            setShowDialog(true);
        } catch (error) {
            console.error('Failed to fetch integration schema:', error);
        }
    };

    const handleInstanceClick = async (integration, instance) => {
        try {
            setSelectedIntegration(integration);
            setSelectedInstance(instance);
            setShowDialog(true);
        } catch (error) {
            console.error('Failed to fetch integration schema:', error);
        }
    };

    const handleDeleteClick = (instance) => {
        setInstanceToDelete(instance);
        setShowDeleteDialog(true);
    };

    const handleDelete = async () => {
        try {
            await InstallableIntegrationsAPI.delete(instanceToDelete.id);
            const updatedInstances = await InstallableIntegrationsAPI.list();
            setInstances(updatedInstances);
            setShowDeleteDialog(false);
            toast({
                title: "Integration Deleted",
                description: "The integration instance was successfully deleted.",
                variant: "success"
            });
        } catch (error) {
            toast({
                title: "Delete Failed",
                description: error.response?.data?.message || error.message || "Failed to delete integration instance",
                variant: "destructive"
            });
        }
    };

    const handleTest = async (integrationName, values, instanceId = null) => {
        setTesting(true);
        setTestingInstanceId(instanceId);
        try {
            const integrationAPI = InstallableIntegrationsAPI.getIntegrationAPI(integrationName);
            await integrationAPI.test(values);
            toast({
                title: `Test ${integrationName} Successful`,
                description: "The integration connection was tested successfully.",
                variant: "success"
            });
        } catch (error) {
            toast({
                title: "Test Failed",
                description: error.response?.data?.message || error.message || "Failed to test integration connection",
                variant: "destructive"
            });
        } finally {
            setTesting(false);
            setTestingInstanceId(null);
        }
    };

    const handleSubmit = async (values) => {
        try {
            if (selectedInstance) {
                // Update existing instance
                const integrationAPI = InstallableIntegrationsAPI.getIntegrationAPI(selectedIntegration.name);
                await integrationAPI.update(selectedInstance.id, values);
            } else {
                // Create new instance
                await InstallableIntegrationsAPI.create({
                    ...values,
                    type: selectedIntegration.name
                });
            }
            const integrationsInstances = await InstallableIntegrationsAPI.list();
            setInstances(integrationsInstances);
            setShowDialog(false);
        } catch (error) {
            console.error('Failed to create/update integration:', error);
        }
    };

    if (loading) {
        return (
            <div className="flex h-screen items-center justify-center">
                <Loader2 className="h-8 w-8 animate-spin" />
            </div>
        );
    }

    const integrationToInstances = {};
    for (const integration of integrations) {
        integrationToInstances[integration.name] = instances.filter(instance => instance.type === integration.name);
    }

    return (
        <div className="container mx-auto py-8">
            <h1 className="text-3xl font-bold mb-8">Available Integrations</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {integrations.map((integration) => (
                    <Card key={integration.title} className="shadow-lg hover:shadow-xl transition-shadow">
                        <CardHeader>
                            <div className="flex items-center justify-between">
                                <CardTitle className="text-xl">{integration.title}</CardTitle>
                                <Badge variant={integrationToInstances[integration.name]?.length ? "success" : "secondary"}>
                                    {integrationToInstances[integration.name]?.length || 0} Instance(s)
                                </Badge>
                            </div>
                            <CardDescription>
                                Configure and manage your {integration.title} integration
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-2">
                                {integrationToInstances[integration.name]?.length === 0 ? (
                                    <Button 
                                        className="w-full"
                                        onClick={() => handleIntegrationClick(integration)}
                                    >
                                        Add New Instance
                                    </Button>
                                ) : (
                                    integrationToInstances[integration.name]?.map(instance => (
                                        <div key={instance.id} className="space-y-2">
                                            <div className="flex gap-2">
                                                <Button
                                                    variant="outline"
                                                    className="flex-1"
                                                    onClick={() => handleInstanceClick(integration, instance)}
                                                >
                                                    Edit Instance
                                                </Button>
                                                <Button
                                                    variant="destructive"
                                                    size="icon"
                                                    onClick={() => handleDeleteClick(instance)}
                                                >
                                                    <Trash2 className="h-4 w-4" />
                                                </Button>
                                            </div>
                                            <Button
                                                variant="secondary"
                                                className="w-full"
                                                onClick={() => handleTest(integration.name, instance, instance.id)}
                                                disabled={testing}
                                            >
                                                {testing && testingInstanceId === instance.id ? (
                                                    <>
                                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                        Testing...
                                                    </>
                                                ) : (
                                                    'Test Connection'
                                                )}
                                            </Button>
                                        </div>
                                    ))
                                )}
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>

            <Dialog open={showDialog} onOpenChange={setShowDialog}>
                <DialogContent className="max-w-2xl">
                    <DialogHeader>
                        <DialogTitle>
                            {selectedInstance ? 'Edit' : 'Configure'} {selectedIntegration?.title} Integration
                        </DialogTitle>
                    </DialogHeader>
                    {selectedIntegration && (
                        <div className="space-y-4">
                            <JsonSchemaForm
                                schema={selectedIntegration}
                                onSubmit={handleSubmit}
                                initialValues={selectedInstance}
                                onChange={setCurrentFormValues}
                            />
                            <div className="flex justify-end space-x-2">
                                <Button
                                    variant="secondary"
                                    onClick={() => handleTest(selectedIntegration.name, currentFormValues)}
                                    disabled={testing}
                                >
                                    {testing ? (
                                        <>
                                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                            Testing...
                                        </>
                                    ) : (
                                        'Test Connection'
                                    )}
                                </Button>
                            </div>
                        </div>
                    )}
                </DialogContent>
            </Dialog>

            <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Integration Instance</DialogTitle>
                        <DialogDescription>
                            Are you sure you want to delete this integration instance? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
                        <Button variant="destructive" onClick={handleDelete}>Delete</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
}