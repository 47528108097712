import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import UserAppAPI from "../../api/UserAppAPI";
import AppCard from "../../components/user-app/AppCard";
import logoImage from '../../logo-v1.png';
import { starters } from './starters';
import ChatTextArea from '../../components/chat/ChatInput';

const AppConversationStarter = ({ onStart, loading }) => {
    const [appDescription, setAppDescription] = useState('');
    const [files, setFiles] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleStart = () => {
        const trimmedDescription = appDescription.trim();
        if (trimmedDescription) {
            onStart({
                text: trimmedDescription,
                files: files
            });
            setAppDescription('');
            setFiles([]);
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const handleSubPromptClick = (description) => {
        setAppDescription(description);
    };

    const handleBackToCategories = () => {
        setSelectedCategory(null);
    };

    return (
        <div>
            <div className="relative">
                <ChatTextArea
                    value={appDescription}
                    files={files}
                    onSend={handleStart}
                    placeholder="Describe the app you want to create..."
                    disabled={loading}
                    isProcessing={loading}
                    onValueChange={setAppDescription}
                    onFilesChange={setFiles}
                />
                
            </div>
            <div className="text-sm text-base-content/50 mt-4">Some ideas to get started:</div>
            <div className="mt-4">
                {selectedCategory ? (
                    <div>
                        <button
                            onClick={handleBackToCategories}
                            className="btn btn-sm btn-ghost mb-2"
                        >
                            <ChevronLeft size={16} />
                            Back to categories
                        </button>
                        <h3 className="text-lg font-semibold mb-2">{selectedCategory} Prompts</h3>
                        <div className="flex flex-wrap gap-2">
                            {starters[selectedCategory].map((subPrompt, index) => (
                                <button
                                    key={index}
                                    className="btn btn-sm btn-ghost bg-gray-100 text-neutral"
                                    onClick={() => handleSubPromptClick(subPrompt.description)}
                                >
                                    {subPrompt.title}
                                </button>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-wrap gap-2">
                        {Object.keys(starters).map((category, index) => (
                            <button
                                key={index}
                                className="btn btn-sm btn-ghost bg-gray-100 text-neutral"
                                onClick={() => handleCategoryClick(category)}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

function Home() {
    const [apps, setApps] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchApps();
    }, []);

    const fetchApps = async () => {
        setIsLoading(true);
        try {
            const appList = await UserAppAPI.list('-updated_date', 10, 0, ['id', 'name', 'user_description', 'status', 'updated_date', 'logo_url', 'created_date', 'created_by'].join(','));
            setApps(appList);
        } catch (error) {
            console.error('Failed to fetch apps:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateApp = async ({text, files}) => {
        if (!text.trim()) {
            alert('Please enter an app description');
            return;
        }
        setIsCreating(true);
        try {
            const newApp = await UserAppAPI.create({
                status: {state: "processing", details: "Starting conversation..."}
            });
            UserAppAPI.addMessage(newApp.id, {content: text, files: files});
            navigate(`/apps/${newApp.id}/editor/workspace/overview`);
        } catch (error) {
            console.error('Failed to create app:', error);
            alert('Failed to create app. Please try again.');
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <div className="container mx-auto px-4 py-8 max-w-4xl">
            <div className="bg-white mb-12 mt-20">
                <div>
                    <h2 className="card-title text-2xl flex items-center mb-4">
                        <img src={logoImage} alt="Base44 Logo" className="w-8 h-8 mr-2" />
                        What would you build today?
                    </h2>
                    <AppConversationStarter onStart={handleCreateApp} loading={isCreating} />
                </div>
            </div>

            <h2 className="text-2xl font-bold mb-6">Your Recent Apps</h2>
            
            {isLoading ? (
                <div className="flex justify-center">
                    <span className="loading loading-spinner loading-lg"></span>
                </div>
            ) : apps.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    {apps.map((app, index) => (
                        <AppCard key={index} app={app} />
                    ))}
                </div>
            ) : (
                <div className="text-center py-12">
                    <h3 className="text-xl mb-4">You haven't created any apps yet.</h3>
                    <p className="text-gray-600">Start by creating your first app above!</p>
                </div>
            )}
        </div>
    );
}

export default Home;
