import React, { createContext, useState, useContext, useEffect } from 'react';
import UserAppAPI from '../api/UserAppAPI';
import { useParams } from 'react-router-dom';
import { entitiesFromApp } from '../api/Entities';
import createIntegrationsClient from '../api/Integrations';
import RegisterToApp from '../components/user-app/RegisterToApp';
import { toast } from 'react-hot-toast';
import _ from 'lodash';

const AppContext = createContext();

function changePageMetadata(title, iconSrc) {
  document.title = title + ' - BASE44';
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = iconSrc;
}

export const AppProvider = ({ children }) => {
    const {appId, pageName} = useParams();
    const [app, setApp] = useState(null);
    // const [currentPage, setCurrentPage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [integrationsSDK, setIntegrationsSDK] = useState(null);
    const [entitiesSDK, setEntitiesSDK] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState(null);
    const [appErrors, setAppErrors] = useState([]);
    const [showRegister, setShowRegister] = useState(false);
    const [registerInfo, setRegisterInfo] = useState(null);
    const [prevPageCode, setPrevPageCode] = useState(null);

    const onAppError = (error) => {
      console.log("OnAppError", error);
        setAppErrors(prevErrors => {
          console.log("prevErrors", prevErrors, !prevErrors.some(e => e.message === error.message));
            if (!prevErrors.some(e => e.message === error.message)) {
                return [...prevErrors, error];
            }
            return prevErrors;
        });
    }

    console.log("appErrors", appErrors);

    

    const init = async () => {
        try {
            setIsLoading(true);
            
            // Load everything in parallel
            const [userInfoPromise, integClientPromise] = await Promise.all([
                UserAppAPI.getMyUserInfo(appId),
                createIntegrationsClient(appId, onAppError),
                UserAppAPI.subscribe(appId, (fetchedApp) => {
                    setApp(fetchedApp);
                    const appEntitiesSDK = entitiesFromApp(fetchedApp, onAppError);
                    setEntitiesSDK(appEntitiesSDK);
                    setAppErrors([]);
                    // const currentPageFound = fetchedApp.pages.find(page => 
                    //   page.name.toLowerCase() === (pageName || currentPage?.name || fetchedApp.pages[0].name).toLowerCase()
                    // );
                    // console.log("setting current page from subscribe", currentPageFound);
                    // setCurrentPage(currentPageFound);
                    changePageMetadata(fetchedApp.name, fetchedApp.logo_url);
                })
            ]);

            // Set state with resolved values
            setUserInfo(userInfoPromise);
            setIntegrationsSDK(integClientPromise);

        } catch (err) {
            if (err.response && err.response.status === 403) {
                setShowRegister(true);
                setRegisterInfo(err.response.data.detail);
            } else {
                setError('Failed to load app: ' + err.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    
    useEffect(() => {
      if (!app || app?.id !== appId) {
        init();
      }
    }, [appId]);


    // useEffect(() => {
    //   console.log("useEffect", app, pageName);
    //   if (app && pageName) {
    //     setCurrentPage(app.pages.find(page => page.name.toLowerCase() === pageName.toLowerCase()));
    //   }
    // }, [pageName]);

    // // reset errors when the page code changes
    // useEffect(() => {
    //   if (currentPage?.code && prevPageCode && prevPageCode !== currentPage.code) {
    //     setAppErrors([]);
    //   } else {
    //     setPrevPageCode(currentPage?.code);
    //   }
    // }, [currentPage?.code]);

  const updateUserInfo = async (userId, newInfo) => {
    try {
      const updatedUserInfo = await UserAppAPI.updateUserData(appId, userId, newInfo);
      setUserInfo(updatedUserInfo);
    } catch (err) {
      console.log(err);
      toast.error("Error updating user info: " + err.message);
    }
  };

  const addChatMessage = async (message) => {
    try {
      const params = currentPage ? {current_page: currentPage.name} : {};
        app.conversation.messages.push({...message, role: 'user'});
        const updatedApp = await UserAppAPI.addMessage(appId, {...message, additional_message_params: params});
        setApp(updatedApp);
    } catch (err) {
        console.error(err);
        setError('Failed to add message: ' + err.message);
    }
  };

  const editChatMessage = async (messageId, message) => {
    try {
      const params = currentPage ? {current_page: currentPage.name} : {};
      let origMsg = app.conversation.messages.find(msg => msg.id === messageId);
      _.assign(origMsg, message);
      const updatedApp = await UserAppAPI.editMessage(appId, messageId, {...message, additional_message_params: params});
      setApp(updatedApp);
    } catch (err) {
      console.error(err);
      setError('Failed to edit message: ' + err.message);
    }
  };

  const undoMessage = async (messageId) => {
    try {
      const updatedApp = await UserAppAPI.undoMessage(appId, messageId);
      setApp(updatedApp);
    } catch (err) {
      console.error(err);
      setError('Failed to undo message: ' + err.message);
    }
  }

  const updateApp = async (props) => {
    const updatedApp = await UserAppAPI.update(appId, props);
    setAppErrors([])
    setApp(updatedApp);
  }

  const makePageChanges = async (pageId, changes, image) => {
    console.log("making page changes", app.id, pageId, changes, image);
    const updatedApp = await UserAppAPI.editPageCode(app.id, pageId, changes, image);
    setApp(updatedApp);
    setAppErrors([]);
}

  const stopChat = async () => {
    const updatedApp = await UserAppAPI.stopChat(app.id);
    setApp(updatedApp);
  }

  let element = children

  if (showRegister) {
    element = <RegisterToApp 
        appId={appId}
        appName={registerInfo.app_name}
        appDescription={registerInfo.app_description}
        logoUrl={registerInfo.app_logo}
        onRegister={() => window.location.reload()}
        />;
    } else if (isLoading) {
        element = <div className="flex justify-center items-center h-full">
            <span className="loading loading-spinner loading-lg"></span>
        </div>;
    } else if (error) {
        // element = <div className="alert alert-error m-4">{error}</div>;
    } else if (!app) {
        element =  <div className="alert alert-error m-4">App not found</div>;
    }


  const currentPage = app?.pages.find(page => page.name.toLowerCase() === (pageName || app.pages[0].name).toLowerCase());



  return (
    <AppContext.Provider value={{
        app,
        setApp,
        updateApp,
        isLoading,
        integrationsSDK,
        entitiesSDK,
        userInfo,
        updateUserInfo,
        error,
        appErrors,
        onAppError,
        addChatMessage,
        undoMessage,
        currentPage,
        makePageChanges,
        editChatMessage,
        stopChat
      }}>
        {element}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);