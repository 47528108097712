import { BaseAPIProvider } from './base'

export class FeedbackAPI extends BaseAPIProvider {
    getBaseURL() {
        return '/feedback'
    }

    create(feedback) {
        return this.axios.post('', feedback)
    }
}

export default new FeedbackAPI()