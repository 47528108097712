import React, { useState } from 'react';

const ToggleWithText = ({ option1, option2, selected, onChange }) => {
  const handleToggle = () => {
    onChange(selected === option1 ? option2 : option1);
  };

  const selectedStyle = 'text-content-primary bg-gray-100 hover:bg-gray-200 focus:z-10 focus:ring-2'
  const unselectedStyle = 'text-base-content/50 hover:bg-gray-100 hover:text-content-primary focus:z-10'

  return (
    <div className="inline-flex rounded-full bg-white p-1 cursor-pointer" role="group">
      <div
        // type="button"
        className={`px-4 py-1 text-sm font-medium rounded-full ${
          selected === option1
            ? selectedStyle
            : unselectedStyle
        }`}
        onClick={handleToggle}
      >
        {option1}
      </div>
      <div
        // type="button"
        className={`px-4 py-1 text-sm font-medium rounded-full ${
          selected === option2
            ? selectedStyle
            : unselectedStyle
        }`}
        onClick={handleToggle}
      >
        {option2}
      </div>
    </div>
  );
};

export default ToggleWithText;
