import { useState } from 'react';
import UserAppAPI  from '../../api/UserAppAPI';
import { toast } from 'react-hot-toast';

export default function RegisterToApp({ appId, appName, appDescription, onRegister, logoUrl }) {
    const [loading, setLoading] = useState(false);

    const handleRegister = async () => {
        setLoading(true);
        try {
            await UserAppAPI.registerUser(appId);
            onRegister();
        } catch (error) {
            console.error('Failed to register:', error);
            toast.error('Failed to register. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-purple-100">
            <div className="card bg-base-100 shadow-xl max-w-md w-full">
                <div className="card-body items-center text-center">
                    {logoUrl && (
                        <div className="avatar mb-6">
                            <div className="w-24 h-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                <img src={logoUrl} alt={`${appName} logo`} />
                            </div>
                        </div>
                    )}
                    <h1 className="card-title text-3xl font-bold mb-4 text-primary">
                        Welcome to {appName}
                    </h1>
                    <p className="mb-6 text-base-content">{appDescription}</p>
                    <p className="mb-8 text-sm text-base-content/70">Join now to access exclusive features and content!</p>
                    <button 
                        className={`btn btn-primary btn-lg ${loading ? 'loading' : ''}`}
                        onClick={handleRegister}
                        disabled={loading}
                    >
                        {loading ? 'Registering...' : 'Join Now'}
                    </button>
                </div>
            </div>
        </div>
    );
}