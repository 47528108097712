import React, { useState } from 'react';
import JsonSchemaForm from '../lib/components/json-schema-form/JsonSchemaForm';
import _ from 'lodash';


const coreSchema = {
    type: "object",
    properties: {
        title: {type: "string", title: "Title", default: ""},
        description: {type: "string", title: "Description", default: null},
        type: {type: "string", title: "Type", default: "string", enum: ["string", "number", "boolean", "object", "array", "binary"]},
    }
}


function createMetaSchema(fieldSchema){
    let metaSchema = _.cloneDeep(coreSchema);
    if (fieldSchema.type === "array"){
        if (fieldSchema.items){
            metaSchema.properties.items = createMetaSchema(fieldSchema.items);
        } else {
            metaSchema.properties.items = _.cloneDeep(coreSchema);
        }
    }

    if (fieldSchema.type === "string"){
        metaSchema.properties.format = {type: "string", title: "Format", default: null, enum: ["date", "email", "file", "uri", "date-time", "ipv4", "ipv6", "regex"]}
        if (fieldSchema.format !== 'file'){
            metaSchema.properties.enum = {type: "array", title: "Enum", default: null, items: {type: "string"}}
        }
    }

    if (fieldSchema.type === "object") {
        console.error("object not implemented");
    }
    
    return metaSchema;
}



export default function MetaJsonSchemaFieldForm({value, onSubmit}){
    const [fieldSchema, setFieldSchema] = useState(value);
    const metaSchema = createMetaSchema(fieldSchema);

    const onFieldSchemaChange = (value) => {
        setFieldSchema(value);
    }

    return (
        <JsonSchemaForm
            schema={metaSchema}
            initialValues={fieldSchema}
            onChange={onFieldSchemaChange}
            onSubmit={onSubmit}
        />
    )
}