import React, { useState, useEffect } from 'react';
import AppLogsAPI from '../../../api/AppLogsAPI';
import { useApp } from '../../../context/AppContext';
import { ChevronDown, ChevronUp, X, AlertCircle, CheckCircle, Info } from 'lucide-react';

export function AppLogs() {
    const { app } = useApp();
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null);
    const [filters, setFilters] = useState({
        userEmail: '',
        showFailuresOnly: false
    });
    const pageSize = 50;

    const fetchLogs = async () => {
        setLoading(true);
        try {
            let query = {};
            if (filters.userEmail) {
                query.user_email = filters.userEmail;
            }
            if (filters.showFailuresOnly) {
                query.status = 'error';
            }
            
            const response = await AppLogsAPI.fetchLogs(app.id, {
                q: query,
                skip: (page - 1) * pageSize,
                limit: pageSize
            });
            setLogs(response);
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (app?.id) {
            fetchLogs();
        }
    }, [app?.id, page, filters]);

    const getStatusIcon = (status) => {
        switch(status) {
            case 'error':
                return <AlertCircle className="text-red-500" size={18} />;
            case 'success':
                return <CheckCircle className="text-green-500" size={18} />;
            default:
                return <Info className="text-blue-500" size={18} />;
        }
    };

    const formatDate = (dateStr) => {
        return new Date(dateStr).toLocaleString();
    };

    const truncateObject = (obj) => {
        const str = JSON.stringify(obj, null, 2);
        return str.length > 100 ? str.substring(0, 100) + '...' : str;
    };

    return (
        <div className="p-8 max-w-6xl mx-auto">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">Application Logs</h2>
                <div className="flex gap-4">
                    <input
                        type="text"
                        placeholder="Filter by email..."
                        className="input input-bordered w-64"
                        value={filters.userEmail}
                        onChange={(e) => setFilters(f => ({...f, userEmail: e.target.value}))}
                    />
                    <label className="flex items-center gap-2 cursor-pointer">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={filters.showFailuresOnly}
                            onChange={(e) => setFilters(f => ({...f, showFailuresOnly: e.target.checked}))}
                        />
                        <span>Show failures only</span>
                    </label>
                </div>
            </div>

            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <span className="loading loading-spinner loading-lg"></span>
                </div>
            ) : (
                <div className="space-y-4">
                    {logs.map((log, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-sm border p-4">
                            <div className="flex justify-between items-start">
                                <div className="flex items-start gap-4">
                                    {getStatusIcon(log.status)}
                                    <div>
                                        <div className="font-medium">{log.object_name || log.log_type}</div>
                                        <div className="text-sm text-gray-500">{formatDate(log.created_date)}</div>
                                        <div className="text-sm text-gray-600">{log.user_email}</div>
                                    </div>
                                </div>
                                <button 
                                    className="btn btn-ghost btn-sm"
                                    onClick={() => setSelectedLog(selectedLog?.created_date === log.created_date ? null : log)}
                                >
                                    {selectedLog?.created_date === log.created_date ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                                </button>
                            </div>

                            {selectedLog?.created_date === log.created_date && (
                                <div className="mt-4 space-y-4">
                                    <div>
                                        <div className="font-medium mb-2">Inputs</div>
                                        <pre className="bg-gray-50 p-4 rounded-lg overflow-x-auto text-sm">
                                            {JSON.stringify(log.inputs, null, 2)}
                                        </pre>
                                    </div>
                                    {log.outputs && (
                                        <div>
                                            <div className="font-medium mb-2">Outputs</div>
                                            <pre className="bg-gray-50 p-4 rounded-lg overflow-x-auto text-sm">
                                                {JSON.stringify(log.outputs, null, 2)}
                                            </pre>
                                        </div>
                                    )}
                                    {log.error && (
                                        <div>
                                            <div className="font-medium mb-2 text-red-500">Error</div>
                                            <pre className="bg-red-50 p-4 rounded-lg overflow-x-auto text-sm text-red-600">
                                                {log.error}
                                            </pre>
                                        </div>
                                    )}
                                </div>
                            )}
                            {!selectedLog && (log.outputs || log.error) && (
                                <div className="mt-2 text-sm text-gray-500">
                                    {log.outputs && <div>Outputs: {truncateObject(log.outputs)}</div>}
                                    {log.error && <div className="text-red-500">Error: {log.error.substring(0, 100)}...</div>}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}

            <div className="mt-6 flex justify-between items-center">
                <button 
                    className="btn btn-outline"
                    disabled={page === 1}
                    onClick={() => setPage(p => Math.max(1, p - 1))}
                >
                    Previous
                </button>
                <span className="text-gray-600">Page {page}</span>
                <button 
                    className="btn btn-outline"
                    disabled={logs.length < pageSize}
                    onClick={() => setPage(p => p + 1)}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default AppLogs;