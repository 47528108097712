import { Send, Link, Stars, Square } from 'lucide-react';
import { useRef } from 'react';
import Files from '../../api/Files';
import { useMediaQuery } from 'react-responsive';

export default function ChatTextArea({value, files = [], onSend, placeholder, disabled, isProcessing, onValueChange, onFilesChange, onStop}) {
    const fileInputRef = useRef();
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const handleSend = () => {
        onSend({
            text: value,
            files: files
        });
        onValueChange('');
        onFilesChange([]);
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
        if (e.key === 'v' && e.ctrlKey) {
            handlePaste(e);
        }
    }

    const handlePaste = async (e) => {
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        for (let item of items) {
            if (item.kind === 'file') {
                const file = item.getAsFile();
                await uploadFile(file);
            }
        }
    }

    const uploadFile = async (file) => {
        try {
            const response = await Files.uploadFile(file);
            console.log('uploaded file', response);
            onFilesChange([...files, response.url]);
        } catch (error) {
            console.error('Failed to upload file:', error);
        }
    }

    const handleFileUpload = async (e) => {
        const files = Array.from(e.target.files);
        for (let file of files) {
            await uploadFile(file);
        }
        fileInputRef.current.value = '';
    }

    const handleDrop = async (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        for (let file of files) {
            await uploadFile(file);
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const removeFile = (fileUrl) => {
        onFilesChange(files.filter(url => url !== fileUrl));
    }

    return (
        <div 
            className='w-full relative shadow-sm border border-gray-200 rounded-lg bg-white/80 backdrop-blur'
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            {files.length > 0 && (
                <div className="flex flex-wrap gap-2 p-2 border-b">
                    {files.map((fileUrl, index) => (
                        <div key={index} className="flex items-center gap-1 bg-gray-100 rounded px-2 py-1 text-sm">
                            <span className="truncate max-w-[200px]">{fileUrl.split('/').pop()}</span>
                            <button 
                                onClick={() => removeFile(fileUrl)}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                ×
                            </button>
                        </div>
                    ))}
                </div>
            )}
            <textarea
                value={value}
                onChange={(e) => onValueChange(e.target.value)}
                onKeyDown={onKeyDown}
                onPaste={handlePaste}
                placeholder={placeholder}
                disabled={disabled}
                className="w-full pl-4 pt-4 pr-16 text-sm resize-none overflow-auto focus:outline-none bg-transparent rounded-t-lg"
                style={{
                    height: 'auto',
                    minHeight: '76px',
                    maxHeight: '400px'
                }}
                onInput={e => {
                    e.target.style.height = 'auto';
                    if (e.target.scrollHeight > 400) {
                        e.target.style.height = '400px';
                    } else {
                        e.target.style.height = e.target.scrollHeight + 'px';
                    }
                }}
            />
            {(value || isProcessing) && (
                <button 
                    onClick={isProcessing ? onStop : handleSend}
                    className="absolute flex justify-center items-center top-4 right-4 p-1 bg-blue-500 hover:bg-blue-600 text-white rounded-md w-[34px] h-[34px] transition-colors"
                >
                    {isProcessing ? (
                        <Square className="w-4 h-4" />
                    ) : (
                        <Send className="w-4 h-4" />
                    )}
                </button>
            )}

            <div className="flex justify-between text-sm p-4 pt-2">
                <div className="flex gap-1 items-center">
                    <input 
                        ref={fileInputRef}
                        type="file" 
                        id="file-upload"
                        className="hidden"
                        multiple
                        onChange={handleFileUpload}
                        accept=".jpg,.jpeg,.png,.gif,.pdf,.txt,.doc,.docx"
                    />
                    <button 
                        onClick={() => document.getElementById('file-upload').click()}
                        className="flex items-center hover:bg-gray-100 rounded-md p-1 text-gray-500"
                        title="Upload files"
                    >
                        <Link className="w-4 h-4" />
                    </button>
                    {/* <button
                        onClick={handleEnhancePrompt}
                        className="flex items-center hover:bg-gray-100 rounded-md p-1 text-blue-500"
                        title="Enhance prompt"
                    >
                        <Stars className="w-5 h-5" />
                        <span className="ml-1.5">Enhance</span>
                    </button> */}
                </div>
                {value && !isMobile && <div className="flex items-center gap-1 text-xs text-gray-500">
                    Press <kbd className="px-1 py-0.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg">Shift</kbd> + <kbd className="px-1 py-0.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg">Enter</kbd> for new line
                </div>}
            </div>
        </div>
    );
}