import { Component } from 'react';
import { generateElement } from './utils';
import _ from 'lodash';

export default class Runner extends Component {
    state = {
      element: null,
      prevCode: null,
      prevScope: undefined,
    }
  
    static getDerivedStateFromProps(props, state) {
      // only regenerate on code/scope change
      if (_.isEqual(state.prevCode, props.code)) {
        return null
      }
  
      try {
        return {
          element: generateElement(props),
          prevCode: props.code,
          prevScope: props.scope,
        }
      } catch (error) {
        props.onError(`Error while trying to compile code for component: ${error.message}\n\nStack: ${error.stack}`);
        return {
          element: null,
          prevCode: props.code,
          prevScope: props.scope,
        }
      }
    }
  
    static getDerivedStateFromError(error) {
        return {
            error: error.message,
        }
    }

    componentDidCatch(error, errorInfo) {
        this.props.onError(`Error: ${error.message}`);
    }

  
    shouldComponentUpdate(nextProps, nextState) {
      return (
        !_.isEqual(nextProps.code, this.props.code) ||
        !_.isEqual(nextProps.scope, this.props.scope) 
      )
    }
  

    render() {
        if (this.state.error) {
            this.props.onError(`Error: ${this.state.error}`);
        }
      return <div>
        {this.state.element || <div>Element could not be compiled</div>}
       </div>
    }
  }