import { BaseAPIModelProvider } from './base'

class AuthAPI extends BaseAPIModelProvider {
    getBaseURL() {
        return '/auth'
    }

    getCurrentUser() {
        return this.axios.get('/me')
    }

    regenerateApiKey() {
        return this.axios.post('/regenerate_api_key')
    }

    updateUser(userId, attributes) {
        return this.axios.post(`/${userId}/update-user`, { ...attributes })
    }
}

export default new AuthAPI();