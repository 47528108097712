import React, { useState, useCallback, useEffect, useRef } from 'react';
import CodeEditor from '../CodeEditor';
import _ from 'lodash';
import UserAppAPI from '../../api/UserAppAPI';
import { useApp } from '../../context/AppContext';


export default function AppCodeEditor({  }) {
    const { app, currentPage } = useApp();
    const [localCode, setLocalCode] = useState(currentPage.code);
    const saveTimeoutRef = useRef(null);

    const onSaveCode = (code) => {
        UserAppAPI.manualEditPageCode(app.id, currentPage.id, code);
    }

    useEffect(() => {
        return () => {
            if (saveTimeoutRef.current) {
                clearTimeout(saveTimeoutRef.current);
            }
        };
    }, []);

    const onChange = (newCode) => {
        setLocalCode(newCode);
        
        if (saveTimeoutRef.current) {
            clearTimeout(saveTimeoutRef.current);
        }

        saveTimeoutRef.current = setTimeout(() => {
            onSaveCode(newCode);
        }, 1000);
    }

    return (
        <div className="h-full">
            <CodeEditor 
            className="h-full"
                value={localCode} 
                language='javascript' 
                readonly={false}
                onChange={onChange}
            />
        </div>
    );
}