import React, { useState } from 'react';
import { Globe, Copy, Trash2, Send, ExternalLink } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import { useApp } from '../../../context/AppContext';
import UserAppAPI from '../../../api/UserAppAPI';
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";

export default function AppSettings() {
    const { app } = useApp();
    const navigate = useNavigate();
    const { toast } = useToast();
    const [appLanguage, setAppLanguage] = useState(app.language || 'english');
    const [isUpdatingLanguage, setIsUpdatingLanguage] = useState(false);
    const [isLoadingPublish, setIsLoadingPublish] = useState(false);
    const [isCloningApp, setIsCloningApp] = useState(false);
    const [userFacingChatPrompt, setUserFacingChatPrompt] = useState(app.user_facing_chat_system_prompt || '');
    const [isUpdatingChatPrompt, setIsUpdatingChatPrompt] = useState(false);

    const handleLanguageChange = (value) => setAppLanguage(value);

    const handleUpdateLanguage = async () => {
        try {
            setIsUpdatingLanguage(true);
            await UserAppAPI.update(app.id, { language: appLanguage });
            toast({
                title: "Success",
                description: "App language updated successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update app language",
                variant: "destructive"
            });
        } finally {
            setIsUpdatingLanguage(false);
        }
    };

    const handleUserFacingChatPromptChange = (e) => setUserFacingChatPrompt(e.target.value);

    const handleUpdateUserFacingChatPrompt = async () => {
        try {
            setIsUpdatingChatPrompt(true);
            await UserAppAPI.update(app.id, { user_facing_chat_system_prompt: userFacingChatPrompt });
            toast({
                title: "Success",
                description: "User facing chat prompt updated successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update user facing chat prompt",
                variant: "destructive"
            });
        } finally {
            setIsUpdatingChatPrompt(false);
        }
    };

    const handleAppPublish = async () => {
        try {
            setIsLoadingPublish(true);
            if (app.app_publish_info === null) {
                await UserAppAPI.publishApp(app.id);
                toast({
                    title: "Success",
                    description: "App published successfully"
                });
            } else {
                await UserAppAPI.unpublishApp(app.id);
                toast({
                    title: "Success",
                    description: "App unpublished successfully"
                });
            }
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update app",
                variant: "destructive"
            });
        } finally {
            setIsLoadingPublish(false);
        }
    };

    const handleCloneApp = async () => {
        try {
            setIsCloningApp(true);
            const newApp = await UserAppAPI.cloneApp(app.id);
            toast({
                title: "Success",
                description: "App cloned successfully"
            });
            navigate(`/apps/${newApp.id}/editor/preview`);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to clone app",
                variant: "destructive"
            });
        } finally {
            setIsCloningApp(false);
        }
    };

    const showDeleteConfirm = () => {
        if (window.confirm('Are you sure you want to delete this app? This action cannot be undone.')) {
            handleDeleteApp();
        }
    };

    const handleDeleteApp = async () => {
        try {
            await UserAppAPI.delete(app.id);
            toast({
                title: "Success",
                description: "App deleted successfully"
            });
            navigate('/');
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to delete app",
                variant: "destructive"
            });
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-8 space-y-8">
            <h2 className="text-3xl font-bold">App Settings</h2>

            <Card className="p-6">
                <h3 className="text-xl font-semibold mb-6">App Language</h3>
                <div className="max-w-md space-y-4">
                    <Select value={appLanguage} onValueChange={handleLanguageChange}>
                        <SelectTrigger>
                            <SelectValue placeholder="Select language" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="english">English</SelectItem>
                            <SelectItem value="french">French</SelectItem>
                            <SelectItem value="spanish">Spanish</SelectItem>
                            <SelectItem value="arabic">Arabic</SelectItem>
                            <SelectItem value="hebrew">Hebrew</SelectItem>
                        </SelectContent>
                    </Select>
                    <Button 
                        onClick={handleUpdateLanguage} 
                        disabled={isUpdatingLanguage}
                        className="w-full"
                    >
                        <Globe className="mr-2 h-4 w-4" />
                        {isUpdatingLanguage ? "Updating..." : "Update"}
                    </Button>
                </div>
            </Card>

            <Card className="p-6">
                <h3 className="text-xl font-semibold mb-6">User Facing Chat</h3>
                <div className="space-y-6">
                    <Textarea
                        value={userFacingChatPrompt}
                        onChange={handleUserFacingChatPromptChange}
                        placeholder="Enter the system prompt for user facing chat"
                        className="min-h-[128px]"
                    />
                    <div className="flex gap-4">
                        <Button 
                            onClick={handleUpdateUserFacingChatPrompt} 
                            disabled={isUpdatingChatPrompt}
                            className="flex-grow"
                        >
                            <Send className="mr-2 h-4 w-4" />
                            {isUpdatingChatPrompt ? "Updating..." : "Update Prompt"}
                        </Button>
                        <Button variant="outline" asChild>
                            <Link to={`/apps/${app.id}/chat`} target="_blank" rel="noopener noreferrer">
                                <ExternalLink className="mr-2 h-4 w-4" />
                                Open Chat
                            </Link>
                        </Button>
                    </div>
                </div>
            </Card>

            <Card className="p-6">
                <h3 className="text-xl font-semibold mb-6">App Publication</h3>
                <div className="space-y-4">
                    {app.app_publish_info === null ? (
                        <Button 
                            onClick={handleAppPublish} 
                            disabled={isLoadingPublish}
                        >
                            <Globe className="mr-2 h-4 w-4" />
                            {isLoadingPublish ? "Publishing..." : "Publish to Marketplace"}
                        </Button>
                    ) : (
                        <>
                            <div className="flex items-center gap-2 text-green-600">
                                <Globe className="h-5 w-5" />
                                <p className="font-medium">Published in Marketplace</p>
                            </div>
                            <Button 
                                onClick={handleAppPublish} 
                                disabled={isLoadingPublish}
                                variant="destructive"
                            >
                                {isLoadingPublish ? "Unpublishing..." : "Unpublish App"}
                            </Button>
                        </>
                    )}
                </div>
            </Card>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <Card className="p-6">
                    <h3 className="text-xl font-semibold mb-6">Clone App</h3>
                    <Button 
                        onClick={handleCloneApp} 
                        disabled={isCloningApp}
                        className="w-full"
                    >
                        <Copy className="mr-2 h-4 w-4" />
                        {isCloningApp ? "Creating Copy..." : "Create Copy"}
                    </Button>
                </Card>

                <Card className="p-6 border-2 border-destructive/20">
                    <h3 className="text-xl font-semibold mb-6 text-destructive">Danger Zone</h3>
                    <Button 
                        onClick={showDeleteConfirm} 
                        variant="destructive"
                        className="w-full"
                    >
                        <Trash2 className="mr-2 h-4 w-4" />
                        Delete App
                    </Button>
                </Card>
            </div>
        </div>
    );
}
