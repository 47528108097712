import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';



function renderObject(value) {
  if (!value) return null;
  const formattedValue = typeof value === 'object' ? 
    Object.entries(value).map(([k, v]) => (
      <div key={k} className="object-property">
        <span className="property-key">{k}:</span>
        <span className="property-value">{typeof v === 'object' ? JSON.stringify(v) : String(v)}</span>
      </div>
    ))
    : String(value);
  return <div className="object-cell">{formattedValue}</div>;
}

function renderArray(value) {
  //  use the renderObject function for each item in the array
  return JSON.stringify(value);
}

const JsonSchemaTable = ({ jsonSchema, data, onCellEdit, renderActions }) => {
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(data);
  }, [data, jsonSchema]);


  if (!jsonSchema || !jsonSchema.properties) {
    throw new Error('JsonSchemaTable requires a jsonSchema with properties, got: ' + JSON.stringify(jsonSchema));
  }

  const columnDefs = useMemo(() => {
    
    let cols = Object.entries(jsonSchema.properties).map(([key, value]) => {
      const colDef = {
        field: key,
        headerName: value.title || key,
        editable: !!onCellEdit,
        headerClass: 'custom-header-class',
        minWidth: 150, // Added minimum width to prevent squeezing
      };

      if (value.enum) {
        colDef.cellEditor = 'agSelectCellEditor';
        colDef.cellEditorParams = {
          values: value.enum
        };
      } else if (value.format === 'date') {
        colDef.cellEditor = 'agDateCellEditor';
        colDef.cellEditorParams = {
          useFormatter: true
        };
        colDef.valueFormatter = params => {
          return params.value ? new Date(params.value).toLocaleDateString() : '';
        };
      } else {
        switch (value.type) {
          case 'string':
            colDef.cellEditor = 'agTextCellEditor';
            break;
          case 'number':
            colDef.cellEditor = 'agNumberCellEditor';
            break;
          case 'boolean':
            colDef.cellRenderer = params => 
              <input type='checkbox' checked={params.value} onChange={() => {}} />;
            colDef.cellEditor = 'agCheckboxCellEditor';
            break;
          case 'array':
            colDef.cellEditor = 'agLargeTextCellEditor';
            colDef.cellRenderer = params => params.value ? renderArray(params.value) : '';
            colDef.minWidth = 200; // Wider minimum for array columns
            break;
          case 'object':
            colDef.cellRenderer = params => renderObject(params.value);
            colDef.minWidth = 200; // Wider minimum for object columns
            break;
          default:
            colDef.cellEditor = 'agTextCellEditor';
        }
      }

      return colDef;
    });

    if (renderActions) {
      cols.push({
        headerName: 'Actions',
        cellRenderer: (params) => renderActions(params.data),
        field: 'actions',
        headerClass: 'custom-header-class',
        minWidth: 100, // Minimum width for actions column
      });
    }
    return cols;
  }, [jsonSchema, !!onCellEdit, renderActions]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    resizable: true,
    enableCellChangeFlash: true
  }), []);

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onCellValueChanged = params => {
    const updatedData = [...rowData];
    const rowIndex = params.node.rowIndex;
    updatedData[rowIndex] = { ...updatedData[rowIndex], ...params.data };
    setRowData(updatedData);
    onCellEdit(params.data, params.colDef.field, params.newValue);
  };

  return (
    <div className="ag-theme-quartz" style={{ height: 400 }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        animateRows={true}
        headerHeight={40}
      />
    </div>
  );
};

export default JsonSchemaTable;
