import React, { useState } from 'react';
import { inferRequiredFields } from './utils';
import _ from 'lodash';
import { toast } from 'react-hot-toast';
import JsonSchemaFormItem from './JsonSchemaFormItem';
import { Button } from "@/components/ui/button";

function setValueAtObject(obj, path, value){
  if (path.length === 0) {
    return value;
  }

  const [head, ...rest] = path;  
  const isArraySetting = _.isNumber(rest[0]);
  const newObj = _.isArray(obj) ? [...obj] : { ...obj };

  if (rest.length === 0) {
    newObj[head] = value;
  } else {
    if (isArraySetting){
      newObj[head] = setValueAtObject(newObj[head] || [], rest, value);
    } else {
      newObj[head] = setValueAtObject(newObj[head] || {}, rest, value);
    }
  }
  return newObj;
}

function getValueAtObject(obj, path){
  if (_.isNil(obj) || path.length === 0) {
    return obj;
  }

  const [head, ...rest] = path;

  if (rest.length === 0) {
    return obj[head];
  } else {
    return getValueAtObject(obj[head], rest);
  }
}


const JsonSchemaForm = ({ schema, onChange, onSubmit, initialValues, uiSchema }) => {
    const [values, setValues] = useState(initialValues || {});
    const [validationErrors, setValidationErrors] = useState({});
    if (!schema || _.isEmpty(schema) || _.isEmpty(schema.properties)){
      throw new Error("Error In JsonSchemaForm: valid json schema is required, but got: " + JSON.stringify(schema));
    }  

    const _validateFieldsToSchema = (schema, values, path = []) => {
        const newValidationErrors = {};

        if (schema.type === 'object' && schema.properties) {
            Object.entries(schema.properties).forEach(([key, propSchema]) => {
                const fieldPath = [...path, key];
                const fieldErrors = _validateFieldsToSchema(propSchema, values, fieldPath);
                if (!_.isEmpty(fieldErrors)) {
                    newValidationErrors[key] = fieldErrors;
                }
            });

            const requiredFields = inferRequiredFields(schema) || [];
            requiredFields.forEach((field) => {
                const fieldPath = [...path, field];
                const fieldValue = getValueAtObject(values, fieldPath) || schema.properties[field].default;
                const fieldUiSchema = getValueAtObject(uiSchema, fieldPath);
                const isHidden = fieldUiSchema?.["ui:widget"] === "hidden";

                if (schema.properties[field].type !== 'boolean' && (fieldValue === undefined || fieldValue === null || fieldValue === '') && !isHidden) {
                    newValidationErrors[field] = 'This field is required';
                    console.log(field, "validation error", schema.properties[field]);
                }
            });
        } else if (schema.type === 'array' && schema.items) {
            const arrayValue = getValueAtObject(values, path) || [];
            arrayValue.forEach((item, index) => {
                const itemPath = [...path, index];
                const itemErrors = _validateFieldsToSchema(schema.items, values, itemPath);
                if (!_.isEmpty(itemErrors)) {
                    newValidationErrors[index] = itemErrors;
                }
            });
        }
        return newValidationErrors;
    }

    const validateFields = () => {
        const errors = _validateFieldsToSchema(schema, values);
        setValidationErrors(errors);
        return _.isEmpty(errors);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const valid = validateFields();
        if (valid) {
          onSubmit(values);
        } else {
          toast('some fields are required: ' + Object.keys(validationErrors).join(', '));
        }
      };


    const setValueAt = (path, value) => {
        const newValues = setValueAtObject(values, path, value);
        setValues(newValues);
        if (onChange) {
            onChange(newValues);
        }
    }
    

    if (_.isFunction(uiSchema)) {
      uiSchema = uiSchema(values);
    }

    // Parse flat uiSchema with dot notation into nested object
    if (uiSchema && typeof uiSchema === 'object') {
      const parsedUiSchema = {};
      Object.entries(uiSchema).forEach(([key, value]) => {
        const parts = key.split('.');
        let current = parsedUiSchema;
        for (let i = 0; i < parts.length - 1; i++) {
          if (!current[parts[i]]) {
            current[parts[i]] = {};
          }
          current = current[parts[i]];
        }
        current[parts[parts.length - 1]] = value;
      });
      uiSchema = parsedUiSchema;
    }

    

    return <form className="pt-4">
        {
            Object.entries(schema.properties).map(([key, value]) => (
                <JsonSchemaFormItem 
                    key={key} 
                    path={[key]} 
                    value={values[key]} 
                    setValueAt={setValueAt} 
                    getValueAt={(path) => getValueAtObject(values, path)}
                    schema={value} 
                    uiSchema={(uiSchema|| {})[key] || {}} 
                    validationErrors={validationErrors[key] || {}} 
                />
            ))
        }

        {onSubmit && <div className="mt-4"><Button onClick={handleSubmit}>Submit</Button></div>}
    </form>
  };
  
export default JsonSchemaForm;
