import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useApp } from '../../../context/AppContext';
import Markdown from '../../../components/Markdown';
import { useToast } from '@/hooks/use-toast';
import UserAppAPI from '../../../api/UserAppAPI';
import { FileText, ExternalLink, BarChart } from 'lucide-react';
import EditInPlace from '../../../components/EditInPlace';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';

export default function AppPage({ page }) {
    const { app, setApp, updateApp } = useApp();
    const [loading, setLoading] = useState(false);
    const { toast } = useToast();

    const handleCodePage = async () => {
        setLoading(true);
        try {
            const updatedApp = await UserAppAPI.codePage(app.id, page.id);
            setApp(updatedApp);
        } catch (err) {
            console.error(err);
            toast({
                title: "Error",
                description: 'Failed to code page: ' + err.message,
                variant: "destructive"
            });
        } finally {
            setLoading(false);
        }
    };

    const handlePageTitleChange = async (pageId, newTitle) => {
        if (app.status?.state !== "ready") {
            toast({
                title: "Error",
                description: "App is still loading... name not updated",
                variant: "destructive"
            });
            return;
        }
        updateApp({pages: app.pages.map(p => p.id === pageId ? { ...p, title: newTitle } : p)});
    }

    return (
        <div key={page.id} className="p-6 space-y-6">
            <h2 className="text-2xl font-bold flex items-center">
                <FileText className="mr-2" size={24} />
                <EditInPlace initialText={page.title} onChange={(text) => handlePageTitleChange(page.id, text)} />
            </h2>
            {
                page.url_parameters?.length > 0 && (
                    <div className="text-sm text-muted-foreground">
                        <p>URL Parameters: {JSON.stringify(page.url_parameters)}</p>
                    </div>
                )
            }
            <div>
                <Markdown>{page.description}</Markdown>
            </div>
            {page.code && (
                <div className="flex gap-4">
                    <Button
                        variant="outline"
                        asChild
                    >
                        <Link 
                            target="_blank"
                            to={`/apps/${app.id}/show/${page.name}`}
                        >
                            <ExternalLink size={16} className="mr-2" />
                            Show Page
                        </Link>
                    </Button>
                    <Button 
                        variant="secondary"
                        onClick={handleCodePage}
                        disabled={loading}
                    >
                        {loading ? 'Implementing...' : 'Re-implement Page'}
                    </Button>
                </div>
            )}
            <Separator />
            <Card className="p-6">
                <h3 className="text-xl font-bold mb-6 flex items-center">
                    <BarChart className="h-6 w-6 mr-2" />
                    Page Analytics
                </h3>
                <div className="text-center text-muted-foreground py-8">
                    <p className="text-lg">✨ Coming Soon ✨</p>
                    <p className="text-sm">Detailed insights about your page's performance and user engagement</p>
                </div>
            </Card>
        </div>
    );
}