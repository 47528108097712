import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { AppProvider } from '../../context/AppContext';
import AppChat from '../../components/user-app/AppChat';
import EditorMain from './EditorMain';
import { useApp } from '../../context/AppContext';
import { MessageCircle } from 'lucide-react';
import AppProductFlows from './editor-subpages/AppProductFlows';

function UserAppEditor({view}) {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [showMobileChat, setShowMobileChat] = useState(false);
    const { app } = useApp();

    // const appInit = app?.pages?.length > 0 || app?.user_data_schema || app?.entities?.length > 0 || app?.product_flows?.length > 0;
    if (app.app_stage === 'pending') {
        return (
            <div className={`h-full overflow-y-auto relative bg-gray-50 ${isMobile ? 'mx-4' : 'mx-auto w-full max-w-2xl'}`}>
                <AppChat />
            </div>
        );
    } 
    
    if (isMobile) {
        // Show editor with chat drawer for apps with pages
        return (
            <div className="drawer drawer-end h-full bg-gray-50">
                <input id="chat-drawer" type="checkbox" className="drawer-toggle" checked={showMobileChat} onChange={e => setShowMobileChat(e.target.checked)} />
                
                <div className="drawer-content">
                    {app.app_stage === "product_flows" ? <AppProductFlows /> : <EditorMain view={view} />}
                    
                    {/* Chat Toggle Button */}
                    {!showMobileChat && (
                        <label
                            htmlFor="chat-drawer"
                            className="fixed bottom-6 right-6 btn btn-primary btn-circle shadow-lg drawer-button"
                        >
                            <MessageCircle size={24} />
                        </label>
                    )}
                </div>

                <div className="drawer-side z-50">
                    <label htmlFor="chat-drawer" className="drawer-overlay"></label>
                    <div className="w-80 h-full bg-gray-50 flex flex-col">
                        <div className="p-4 border-b bg-gray-50 z-10">
                            <div className="flex justify-between items-center">
                                <h3 className="text-lg font-semibold">AI Chat</h3>
                                <label 
                                    htmlFor="chat-drawer"
                                    className="btn btn-sm btn-ghost"
                                >
                                    ✕
                                </label>
                            </div>
                        </div>
                        <div className="flex-1 relative">
                            <AppChat />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // Desktop layout
    return (
        <div className="flex flex-row h-screen bg-base-100">
            <div className="w-[450px] flex-shrink-0 h-full overflow-y-auto px-4 relative">
                <div className="flex flex-col gap-4 relative h-full">
                    <AppChat />
                </div>
            </div>
            {app.app_stage === "product_flows" ? <AppProductFlows /> : <EditorMain view={view} />}
        </div>
    );
}

export default ({view}) => <AppProvider>
    <UserAppEditor view={view} />
</AppProvider>;