import React, { useState } from 'react';

const Tags = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      const newValues = [...(value || []), inputValue.trim()];
      onChange(newValues);
      setInputValue('');
    } else if (e.key === 'Backspace' && !inputValue && value && value.length > 0) {
      const newValues = value.slice(0, -1);
      onChange(newValues);
    }
  };

  const removeTag = (index) => {
    const newValues = value.filter((_, i) => i !== index);
    onChange(newValues);
  };

  return (
    <div className="flex flex-wrap items-center border rounded-md p-1">
      {value && value.map((tag, index) => (
        <span key={index} className="bg-gray-200 rounded-md px-2 py-1 m-1 flex items-center">
          {tag}
          <button
            type="button"
            onClick={() => removeTag(index)}
            className="ml-1 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            &times;
          </button>
        </span>
      ))}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        className="flex-grow outline-none px-2 py-1"
        placeholder="enter values..."
      />
    </div>
  );
};

export default Tags;
