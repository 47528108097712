import { BaseAPIProvider } from './base'

export class FilesAPI extends BaseAPIProvider {
    getBaseURL() {
        return '/files'
    }

    uploadFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        return this.axios.post('/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}

export default new FilesAPI()