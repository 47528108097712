import React, { useState, useEffect } from 'react';
import Chat from '../components/chat/Chat';
import AppUserFacingChatAPI from '../api/AppUserFacingChatAPI';
import { useApp } from '../context/AppContext';
import { AppProvider } from '../context/AppContext';

export function AppUserFacingChat({extraInstructions}) {
    const { app } = useApp();
    const [chat, setChat] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [resetting, setResetting] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const handleAddMessage = async (message) => {
        try {
            chat.conversation.messages.push({role: "user", ...message});
            const updatedChat = await AppUserFacingChatAPI.addMessage(chat.id, message, extraInstructions);
            setChat(updatedChat);
        } catch (err) {
            console.error(err);
            setError('Failed to add message: ' + err.message);
        }
    };

    const handleResetChat = async () => {
        setResetting(true);
        try {
            const resetChat = await AppUserFacingChatAPI.resetChat(chat.id);
            setChat(resetChat);
        } catch (err) {
            console.error(err);
            setError('Failed to reset chat: ' + err.message);
        } finally {
            setResetting(false);
        }
    };

    useEffect(() => {
        const fetchChat = async () => {
            setLoading(true);
            try {
                const fetchedChat = await AppUserFacingChatAPI.getOrCreate(app.id);
                await AppUserFacingChatAPI.subscribe(fetchedChat.id, (fetchedChat) => {
                    setChat(fetchedChat);
                });
            } catch (err) {
                console.error(err);
                setError('Failed to load chat: ' + err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchChat();
    }, [app.id]);

    if (loading) {
        return <div className="flex justify-center items-center h-screen"><span className="loading loading-spinner loading-lg"></span></div>;
    }

    if (!chat || !app) {
        return <div className="alert alert-error m-4">Chat or App not found</div>;
    }

    return (
        <div className={`flex flex-col items-center min-h-full ${isMobile ? '' : ''}`}>
            <div className={`w-full ${isMobile ? 'h-screen' : 'max-w-4xl bg-base-100 overflow-hidden'}`}>
                <div className={`flex items-center justify-between ${isMobile ? 'bg-base-100 p-2' : 'bg-base-100 rounded-t-lg p-4'}`}>
                    <div className="flex items-center">
                        {app.logo_url && (
                            <img 
                                src={app.logo_url} 
                                alt={`${app.name} logo`} 
                                className={`${isMobile ? 'w-8 h-8 mr-2' : 'w-12 h-12 mr-4'} rounded-full`}
                            />
                        )}
                        <h2 className={`${isMobile ? 'text-lg' : 'text-2xl'} font-bold m-0`}>{app.name}</h2>
                    </div>
                    <button 
                        onClick={handleResetChat} 
                        disabled={resetting}
                        className={`btn btn-sm ${isMobile ? 'btn-xs' : ''}`}
                    >
                        {resetting ? <span className="loading loading-spinner loading-xs"></span> : 'Reset Chat'}
                    </button>
                </div>
                <div className={`w-full ${isMobile ? 'h-[calc(100%-48px)]' : 'flex-grow'}`}>
                    <Chat chat={chat.conversation} addMessage={handleAddMessage} status={chat.status} />
                </div>
                
            </div>
            
        </div>
    );
}

export default () => (
    <AppProvider>
        <AppUserFacingChat />
    </AppProvider>
);