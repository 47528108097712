import React from 'react';
import UserAppAPI from '../../api/UserAppAPI';
import JsonSchemaForm from '../../lib/components/json-schema-form/JsonSchemaForm';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";

const InviteUserModal = ({ isVisible, onCancel, appId, onInviteSuccess }) => {
  const { toast } = useToast();

  const handleInvite = async (values) => {
    try {
      await UserAppAPI.inviteUser(appId, values.email, values.role);
      toast({
        title: "Success",
        description: "Invitation sent successfully"
      });
      onInviteSuccess();
      onCancel();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to send invitation",
        variant: "destructive"
      });
    }
  };

  const schema = {
    type: 'object',
    properties: {
      email: {
        type: 'string',
        format: 'email',
        title: 'Email',
        placeholder: 'Enter email'
      },
      role: {
        type: 'string',
        enum: ['user', 'admin'],
        title: 'Role'
      }
    },
    required: ['email', 'role']
  };

  return (
    <Dialog open={isVisible} onOpenChange={onCancel}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Invite User</DialogTitle>
        </DialogHeader>
        <JsonSchemaForm
          schema={schema}
          onSubmit={handleInvite}
        />
        <DialogFooter>
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default InviteUserModal;
