import React from 'react';
import { useApp } from '../../../context/AppContext';
import { useToast } from '@/hooks/use-toast';
import { Play, Share2, Star } from 'lucide-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import EditInPlace from '../../../components/EditInPlace';

export default function AppOverview() {
    const { app, updateApp } = useApp();
    const navigate = useNavigate();
    const { toast } = useToast();
    const appLink = `/apps/${app.id}/show`;

    const handleOpenApp = () => {
        navigate(`/apps/${app.id}/editor/preview/${app.pages[0].name}`);
    };

    const handleShareApp = () => {
        const fullAppLink = `${window.location.origin}${appLink}`;
        navigator.clipboard.writeText(fullAppLink);
        toast({
            title: "Success",
            description: "App link copied to clipboard"
        });
    };

    const handleAppNameChange = async (newName) => {
        if (app.status?.state !== "ready") {
            toast({
                title: "Error", 
                description: "App is still loading... name not updated",
                variant: "destructive"
            });
            return;
        }
        updateApp({name: newName});
    };

    const handleToggleStar = () => {
        updateApp({ is_starred: !app.is_starred });
        toast({
            title: app.is_starred ? "Removed from favorites" : "Added to favorites",
            description: app.is_starred ? "App removed from your starred list" : "App added to your starred list"
        });
    };

    return (
        <div className="p-6 max-w-4xl mx-auto">
            <div className="lg:p-6">
                <div className="flex items-center mb-6">
                    {app.logo_url && (
                        <img
                            src={app.logo_url}
                            alt={`${app.name} logo`}
                            className="w-20 h-20 rounded-lg object-cover mr-6 border"
                        />
                    )}
                    <div className="flex-grow">
                        <div className="flex items-center justify-between">
                            <h1 className="text-3xl font-bold mb-2">
                                <EditInPlace initialText={app.name} onChange={handleAppNameChange} />
                            </h1>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={handleToggleStar}
                                className={`hover:bg-yellow-100 transition-colors ${app.is_starred ? 'text-yellow-500' : 'text-gray-400'}`}
                            >
                                <Star className={`h-5 w-5 ${app.is_starred ? 'fill-current' : ''}`} />
                            </Button>
                        </div>
                        <p className="text-muted-foreground">{app.user_description}</p>
                    </div>
                </div>

                <div className="flex gap-4 mb-8">
                    <Button onClick={handleOpenApp} variant="default" size="sm">
                        <Play className="h-4 w-4 mr-2" />
                        Open App
                    </Button>
                    <Button onClick={handleShareApp} variant="outline" size="sm">
                        <Share2 className="h-4 w-4 mr-2" />
                        Share App
                    </Button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <Card className="p-6">
                        <h2 className="text-xl font-semibold mb-4">App Details</h2>
                        <div className="space-y-2">
                            <p><span className="font-medium">Status:</span> {app.status.state}</p>
                            <p><span className="font-medium">Language:</span> {app.language || 'English'}</p>
                            <p><span className="font-medium">Created:</span> {moment.utc(app.created_date).fromNow()}</p>
                        </div>
                    </Card>

                    {app.status.state === "processing" && (
                        <Card className="p-6 flex items-center">
                            <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary border-t-transparent" />
                            <div className="text-muted-foreground ml-3">{app.status.details}</div>
                        </Card>
                    )}
                </div>
            </div>
        </div>
    );
}
