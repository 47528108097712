import React from 'react';
import _ from 'lodash';
import { X, Plus } from 'lucide-react';
import ForeignKeyInput from './inputs/ForeignKeyInput';
import DateInput from './inputs/DateInput';
import FileUpload from './inputs/FileUpload';
import Select from './inputs/Select';
import Checkbox from './inputs/Checkbox';
import Tags from './inputs/Tags';
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

function ArrayItem({ path, schema, value, setValueAt, getValueAt, uiSchema, validationErrors }) {
  const parsedValue = value || [];
  if (schema.items.type === 'string' && !schema.items.$ref && !schema.items.enum && !schema.items.format) {
    return (
      <Tags value={parsedValue} onChange={(v) => setValueAt(path, v)} />
    );
  }

  if (schema.items.type === "string" && schema.items.enum) {
    return (
      <Select 
        options={schema.items.enum} 
        isMulti={true} 
        value={parsedValue} 
        onChange={(v) => setValueAt(path, v)} 
        optionsNames={schema.items.enumNames}
      />
    );
  }

  if (schema.items.type === "string" && schema.items.format === "file") {
    return (
      <FileUpload value={parsedValue} onChange={(v) => setValueAt(path, v)} isMulti={true} />
    );
  }

  if (schema.items.$ref) {
    return <ForeignKeyInput value={parsedValue} onChange={(v) => setValueAt(path, v)} 
        name={path[path.length - 1]} 
        itemSchema={schema.items} 
        isMulti={true}
    />
  }


  const newItemDefaultValue = schema.items.default || schema.items.type === 'object' ? {} : null;
  const onAddClick = (e) => {
      e.preventDefault();
      setValueAt([...path, parsedValue.length], newItemDefaultValue);
  }
  const onRemoveClick = (index) => {
      const newValue = [...parsedValue];
      newValue.splice(index, 1);
      setValueAt(path, newValue);
  }
  if (!schema.hasOwnProperty('items')){
    throw new Error(`Error in json schema for array: items is required. path: ${path.join('.')}`);
  }
  return <div className="space-y-4 ps-4 border-s-2 border-gray-200">
      {
          parsedValue.map((item, index) => (
            <div key={index} className="relative">
              <button 
                className="absolute top-0 right-0 p-1 text-gray-500 hover:text-gray-700"
                onClick={() => onRemoveClick(index)}
              >
                <X size={16} />
              </button>
              <JsonSchemaFormItem 
                  key={index} 
                  path={[...path, index]} 
                  value={item} 
                  schema={schema.items} 
                  setValueAt={setValueAt}
                  getValueAt={getValueAt}
                  uiSchema={uiSchema}
                  validationErrors={validationErrors[index] || {}}
              />
            </div>
          ))
      }
      <div className="mt-2">
        <button type="button" onClick={onAddClick} className="btn btn-outline btn-sm">
            <Plus size={16} /> Add Item
        </button>
      </div>
  </div>
}

export default function JsonSchemaFormItem({ path, getValueAt, setValueAt, schema, uiSchema, validationErrors}){
    if (uiSchema['ui:widget'] === 'hidden'){
        return <></>;
    }
    if (!schema){
      throw new Error(`Error in json schema: schema for the property is required. property path: ${path.join('.')}`);
    }
    const value = getValueAt(path) === undefined ? (schema.default || null) : getValueAt(path);
    
    const renderItem = () => {
        if (_.isFunction(uiSchema['ui:widget'])){
          // return this component with value and onChange
            return uiSchema['ui:widget']({
              value: value,
              onChange: (v) => setValueAt(path, v),
              getValueAt: getValueAt,
              setValueAt: setValueAt,
              schema: schema,
              uiSchema: uiSchema,
              path: path
            });
        }
        if (schema.enum && (schema.type !== 'array') && (schema.type !== 'object')) {
            return <Select options={schema.enum} optionsNames={schema.enumNames} value={value} onChange={(v) => setValueAt(path, v)} />
        }
        if (schema.$ref) {
            return <ForeignKeyInput value={value} onChange={(v) => setValueAt(path, v)} name={path[path.length - 1]} itemSchema={schema} />
        }
        if (schema.type === 'string'){
            if (schema.format === 'date' || schema.format === 'date-time' || schema.format === 'time') {
                return <DateInput format={schema.format} value={value} onChange={(v) => setValueAt(path, v)} />
            }
            if (schema.format === 'file') {
                return <FileUpload value={value} onChange={(v) => setValueAt(path, v)} />;
            }
            if (uiSchema['ui:widget'] === 'textarea') {
                return <Textarea value={value || ''} onChange={(e) => setValueAt(path, e.target.value)} />;
            }
            return <Input type="text" value={value || ''} onChange={(e) => setValueAt(path, e.target.value)} />
        } else if (schema.type === 'number' || schema.type === 'integer'){
            return <Input 
              type="number" 
              value={value || ''} 
              onChange={(e) => setValueAt(path, parseFloat(e.target.value))} 
              min={schema.minimum} 
              max={schema.maximum} 
              step={schema.type === 'integer' ? 1 : 'any'} 
            />
        } else if (schema.type === 'boolean'){
            return <Checkbox value={value} onChange={(value) => setValueAt(path, value)} />
        } else if (schema.type === 'array'){
            return <ArrayItem 
                path={path} 
                schema={schema} 
                value={value} 
                setValueAt={setValueAt} 
                getValueAt={getValueAt}
                uiSchema={uiSchema.hasOwnProperty('items') ? uiSchema.items : uiSchema}
                validationErrors={validationErrors}
            />
        } else if (schema.type === 'object'){
          if (!schema.properties){
            throw new Error(`Error in json schema: properties is required for object type. property path: ${path.join('.')}. if you need the user to insert a flexible object, JsonSchemaFrom might not be the right choice`);
          }
            return <div className="space-y-4 ps-4 border-s-2 border-gray-200">
                {Object.entries(schema.properties).map(([key, value]) => (
                    <JsonSchemaFormItem 
                        key={key} 
                        path={[...path, key]} 
                        getValueAt={getValueAt}
                        setValueAt={setValueAt} 
                        schema={value} 
                        uiSchema={uiSchema[key] || {}} 
                        validationErrors={validationErrors[key] || {}} 
                    />
                ))}
            </div>
        }
    }


    let title;
    if (uiSchema['ui:title']) {
        title = uiSchema['ui:title'];
    } else if (schema.title) {
        title = schema.title;
    } else {
        const lastElement = path[path.length - 1];
        if (typeof lastElement === 'number') {
            title = undefined;
        } else {
            title = lastElement;
        }
    }

    return <div className="mt-4">
      {title && (
        <div className="mb-2">
          <label htmlFor={path.join('__')} className="text-sm font-medium text-gray-900">{title}</label>
          {schema.description && <p className="text-sm text-gray-500">{schema.description}</p>}
        </div>
      )}
      {renderItem()}
      {validationErrors && _.isString(validationErrors) && <div style={{ color: 'red' }}>{validationErrors}</div>}
    </div>
}