import { BaseAPIModelProvider } from './base';
import UserAppAPI from './UserAppAPI';

function getEntityClass(appId, entity, onError){
    class _Entity extends BaseAPIModelProvider {
        constructor() {
            super();
            this.axios.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    onError(
                        `When using the app, the folloing entity api call failed: 
entity: ${entity.name}
url: ${error.config.baseURL}${error.config.url}
method: ${error.config.method}
data: ${error.config.data}
error:${error.response?.data?.message || error.message}
fulljson:${JSON.stringify(error.response?.data)}`
                    );
                    return Promise.reject(error);
                }
            );
        }

        getBaseURL(){
            return `/apps/${appId}/entities/${entity.name}`
        }

        schema(){
            return entity;
        }

        bulkCreate(data){
            return this.axios.post(`/bulk`, data);
        }

        importEntities(file){
            const formData = new FormData();

            formData.append('file', file, file.name);
            return this.axios.post(`/import`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        }
    }

    return new _Entity();
}

export function entitiesFromApp(app, onError){
    let entities = {};
    app.entities.forEach(entity => {
        entities[entity.name] = getEntityClass(app.id, entity, onError);
    });

    entities.User = getEntityClass(app.id, app.full_user_entity_schema, onError);
    entities.User.updateMyUserData = function(data){
        return entities.User.axios.put(`/me`, data);
    }

    entities.User.me = async function(){
        return await UserAppAPI.getMyUserInfo(app.id);
    }

    return new Proxy(entities, {
        get: function(target, name) {
            if (name in target) {
                return target[name];
            }
            throw new Error(`Entity ${name} does not exist`);
        }
    });
}