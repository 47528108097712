import { useState, useEffect, useRef } from 'react'
import { Input } from "@/components/ui/input"

export default function EditInPlace({ initialText, onChange, className = "" }) {
  const [isEditing, setIsEditing] = useState(false)
  const [text, setText] = useState(initialText)
  const inputRef = useRef(null)

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditing])

  const handleEditClick = () => {
    setIsEditing(true);
  }

  const handleChange = (e) => {
    setText(e.target.value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      onChange(text);
    }
  }

  const handleBlur = () => {
    setIsEditing(false);
    onChange(text);
  }

  return (
    <div className="inline-block">
      {isEditing ? (
        <Input
          ref={inputRef}
          type="text"
          value={text}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          className={`w-auto ${className}`}
          style={{ width: `${text.length * 0.8}ch` }}
        />
      ) : (
        <div
          onClick={handleEditClick}
          className={`cursor-pointer hover:bg-gray-100 rounded transition-colors ${className}`}
        >
          {text}
        </div>
      )}
    </div>
  )
}