import React, { createContext, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, MessageSquare } from 'lucide-react';
import Feedback from './Feedback';

const TopbarContext = createContext();

export function TopbarProvider({ children }) {
  const [centerContent, setCenterContent] = useState('');
  const [rightContent, setRightContent] = useState('');
  
  return (
    <TopbarContext.Provider 
      value={{
        setCenterContent,
        setRightContent,
        centerContent,
        rightContent
      }}
    >
      {children}
    </TopbarContext.Provider>
  );
}

export const useTopbar = () => {
  return useContext(TopbarContext);
}

export const MobileTopBar = ({ pages }) => {
  const { centerContent, rightContent } = useTopbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const menuItems = pages.filter(page => !page.hide).map((page) => ({
    key: page.url,
    label: (
      <div className="flex items-center gap-3">
        {React.cloneElement(page.icon, { size: 20 })}
        <span>{page.name}</span>
      </div>
    ),
    onClick: () => {
      navigate(page.url);
      setMenuOpen(false);
    },
  }));

  return (
    <div className="h-12 z-50 bg-gray-100 border-b border-gray-200">
      <div className="flex items-center h-full px-2">
        <div className="w-12">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <Menu size={24} />
            </label>
            <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
              {menuItems.map((item) => (
                <li key={item.key}>
                  <a
                    className={location.pathname === item.key ? 'active' : ''}
                    onClick={item.onClick}
                  >
                    {item.label}
                  </a>
                </li>
              ))}
              <li>
                <a onClick={() => setFeedbackOpen(true)}>
                  <div className="flex items-center gap-3">
                    <MessageSquare size={20} />
                    <span>Feedback</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex-1 text-center">
          {centerContent}
        </div>

        <div className="w-12 flex justify-end">
          {rightContent}
        </div>
      </div>
      <Feedback isOpen={feedbackOpen} onClose={() => setFeedbackOpen(false)} />
    </div>
  );
};
