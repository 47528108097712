import { useState, useEffect } from 'react';    
import { useApp } from '../../../../context/AppContext';
import Select from './Select';

export default function ForeignKeyInput({ value, onChange, name, itemSchema, isMulti = false }) {
    const { entitiesSDK } = useApp();
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  
    useEffect(() => {
      setIsLoading(true);
      // $ref should be like this: /entities/Person/id, we need to get Person
      // first = validate $ref structure is corret:
  
      const refParts = itemSchema.$ref.split('/');
      if (refParts.length !== 4 || refParts[0] !== '' || refParts[1] !== 'entities' || refParts[3] !== 'id') {
        throw new Error(`Error in ${name} field. Invalid $ref format: ${itemSchema.$ref}. should be like /entities/EntityName/id`);
      }
  
      const entityName = refParts[2];
      entitiesSDK[entityName].list().then((data) => {
        setOptions(data.map((item) => {
          const entitySchema = entitiesSDK[entityName].schema();   
          console.log(entitySchema, "entitySchema!!!");
          let nameField = 'id';
          for (const key in entitySchema.properties) {
            if (key.toLowerCase().includes('name') || key.toLowerCase().includes('title')) {
              nameField = key;
              break;
            }
          }
          let itemLabel = item[nameField];
          
          return {
            label: itemLabel, 
            value: item.id 
          }
        }));
        setIsLoading(false);
      });
    }, [itemSchema.$ref]);
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
    return <Select options={options} onChange={onChange} isMulti={isMulti} value={value} />
  }
  
  