import { BaseAPIModelProvider } from './base'

class _AppUserFacingChatAPI extends BaseAPIModelProvider {
    getBaseURL() {
        return '/app-user-facing-chats'
    }

    createFromMessage(msg) {
        return this.axios.post('/chat/create-from-message', msg)
    }

    addMessage(itemId, message, extraInstructions) {
        return this.axios.post(`/${itemId}/chat/message`, { ...message, extra_instructions: extraInstructions })
    }

    getOrCreate(appId) {
        return this.axios.post(`/${appId}/get-or-create`)
    }

    resetChat(itemId) {
        return this.axios.post(`/${itemId}/chat/reset`)
    }
}

const AppUserFacingChatAPI = new _AppUserFacingChatAPI();

export default AppUserFacingChatAPI;