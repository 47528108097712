import { useAuth } from "../context/AuthContext"

export default function UserAvatar({userEmail, userFullName}) {
    return (
        <div className="flex-shrink-0">
            <div 
                className="w-8 h-8 rounded-full bg-gray-600 text-white border border-gray-300 flex items-center justify-center"
                title={`${userFullName || 'Unknown'} (${userEmail || 'No email'})`}
            >
                <span className="text-xs">{userFullName?.charAt(0)}</span>
            </div>
        </div>
    )
}

export function MyUserAvatar() {
    const {user} = useAuth();
    return <UserAvatar userEmail={user?.email} userFullName={user?.full_name} />
}