import React, { useState, useEffect } from 'react';
import { User } from 'lucide-react';
import { useApp } from '../../../context/AppContext';
import { useToast } from '@/hooks/use-toast';
import InviteUserModal from '../../../components/user-app/InviteUserModal';
import UserAppAPI from '../../../api/UserAppAPI';
import { Button } from '@/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';

export default function AppUsers() {
    const { app, entitiesSDK } = useApp();
    const { toast } = useToast();
    const [users, setUsers] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await entitiesSDK.User.list();
            setUsers(response);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to fetch users",
                variant: "destructive"
            });
        }
    };

    const handleRoleChange = async (user) => {
        try {
            await UserAppAPI.updateUserRole(app.id, user.email, 'admin');
            toast({
                title: "Success",
                description: `${user.email} is now an admin`
            });
            fetchUsers();
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update user role",
                variant: "destructive"
            });
        }
    };

    const showModal = () => setIsModalVisible(true);
    const handleCancel = () => setIsModalVisible(false);
    const handleInviteSuccess = () => fetchUsers();

    return (
        <div className="p-8">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">Users</h2>
                <Button onClick={showModal} size="sm">
                    <User className="mr-2 h-4 w-4" />
                    Invite User
                </Button>
            </div>

            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>User</TableHead>
                            <TableHead>Role</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {users.filter(user => !user.email.endsWith('@base44.com')).map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>
                                    <div className="flex flex-col">
                                        <span className="font-medium">{user.name}</span>
                                        <span className="text-sm text-muted-foreground">{user.email}</span>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <Badge variant={user._app_role === 'admin' ? 'default' : 'secondary'}>
                                        {user._app_role}
                                    </Badge>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => handleRoleChange(user)}
                                        variant="ghost"
                                        size="sm"
                                        disabled={user._app_role === 'admin'}
                                    >
                                        Make Admin
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>

            <InviteUserModal
                isVisible={isModalVisible}
                onCancel={handleCancel}
                appId={app.id}
                onInviteSuccess={handleInviteSuccess}
            />
        </div>
    );
}
