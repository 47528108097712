import React, { useState } from 'react';
import { Image as ImageIcon } from 'lucide-react';
import { useApp } from '../../../context/AppContext';
import { useToast } from '@/hooks/use-toast';
import UserAppAPI from '../../../api/UserAppAPI';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { Card } from '@/components/ui/card';

export default function AppLogoSettings() {
    const { app } = useApp();
    const { toast } = useToast();
    const [isGeneratingLogo, setIsGeneratingLogo] = useState(false);
    const [logoPrompt, setLogoPrompt] = useState(app.logo_prompt || 'A modern, minimalist logo for a business app');

    const handleRegenerateLogo = async () => {
        try {
            setIsGeneratingLogo(true);
            await UserAppAPI.generateLogo(app.id, logoPrompt);
            toast({
                title: "Success",
                description: "Logo regenerated successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to regenerate logo",
                variant: "destructive"
            });
        } finally {
            setIsGeneratingLogo(false);
        }
    };

    const handlePromptChange = (e) => setLogoPrompt(e.target.value);

    return (
        <div className="p-8 space-y-8">
            <h2 className="text-3xl font-bold tracking-tight">App Logo</h2>
            
            <div className="space-y-6">
                {app.logo_url && (
                    <div className="flex justify-center">
                        <Card className="w-32 h-32 overflow-hidden">
                            <img 
                                src={app.logo_url} 
                                alt="App Logo"
                                className="w-full h-full object-cover"
                            />
                        </Card>
                    </div>
                )}

                <div className="space-y-2">
                    <Label htmlFor="logo-prompt">Logo Generation Prompt</Label>
                    <Textarea
                        id="logo-prompt"
                        value={logoPrompt}
                        onChange={handlePromptChange}
                        className="min-h-[100px]"
                        placeholder="Describe how you want your logo to look"
                    />
                </div>

                <Button 
                    onClick={handleRegenerateLogo}
                    disabled={isGeneratingLogo}
                    className="w-full"
                >
                    <ImageIcon className="mr-2 h-4 w-4" />
                    {isGeneratingLogo ? 'Generating...' : 'Generate New Logo'}
                </Button>
            </div>
        </div>
    );
}
