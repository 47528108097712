import './index.css';
import './App.css';

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext.js';
import Login from './pages/Login.js';
import PageNotFound from './pages/PageNotFound.js';
import { pages } from './pages/index.js';
import Layout from './components/layout/Layout.js';
import _ from 'lodash';
import { BACKEND_URL } from './config.js';
import { useParams } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import PrivacyPolicy from "./pages/legal/PrivacyPolicy.js";
import TermsOfService from "./pages/legal/TermsOfService.js";

function NavigateToFile(){
  const { fileId } = useParams();
  window.location.href = `${BACKEND_URL}/api/files/${fileId}`;
}



const App = () => {
  const flatPages = _.flatMapDeep(pages, page => [page, ...(page.children || [])]);
  return (
    <div className="font-sans">
      <div><Toaster/></div>

      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            {flatPages.map(p => 
              p.usesLayout === false ? (
                <Route key={p.url} path={p.url} element={p.component} />
              ) : (
                <Route key={p.url} path={p.url} element={
                  <Layout pages={pages.filter(p => p.showInMenu !== false)}>
                    {p.component}
                  </Layout>
                } />
              )
            )}
            <Route path="/api/files/:fileId" element={<NavigateToFile />} replace />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
};

export default App;