import { useState, useEffect, useRef } from 'react';
import { Send, Wrench } from 'lucide-react';
import { toast } from 'react-hot-toast';
import Markdown from '../Markdown';
import Collapsable from '../Collapsable';
import JsonSchemaForm from '../../lib/components/json-schema-form';
import _ from 'lodash';
import moment from 'moment';
import UserAvatar from '../UserAvatar';
import ChatTextArea from './ChatInput';

function ToolCall({ toolCall, submitUserInputTool }) {
    // TODO: find a better way to understand if the tool call is an input tool
    if (toolCall.arguments.input_json_schema) {
        return <JsonSchemaForm 
            schema={toolCall.arguments.input_json_schema}
            onSubmit={(values) => submitUserInputTool(toolCall.id, values)}
        />
    }


    const formatArguments = (args) => {
        if (typeof args === 'object' && args !== null) {
            return Object.entries(args).map(([key, value]) => {
                const stringValue = typeof value === 'object' ? JSON.stringify(value) : value.toString();
                return (
                    <div key={key} className="mb-1 text-sm">
                        <span className="font-semibold">{key}:</span>{' '}
                        {stringValue.split('\n').map((line, i) => (
                            <span key={i}>
                                {i > 0 && <br />}
                                {line}
                            </span>
                        ))}
                    </div>
                );
            });
        }
        return args;
    };

    return (
        <Collapsable
            title={
                <div className="font-small flex items-center">
                    <Wrench className="w-4 h-4 mr-2" />
                    <span className="font-semibold">Action: {toolCall.name}</span>
                </div>
            }
        >
            <div className="ml-2 text-gray-700">
                {formatArguments(toolCall.arguments)}
            </div>
        </Collapsable>
    );
}

function ToolResults({ toolResults }) {
    const displayResults = toolResults.display_results;
    if (_.isObject(displayResults)) {
        const {widget, data} = displayResults;
        if (widget === 'table') {
            return (
                <div className="overflow-x-auto">
                    <table className="table w-full bg-white">
                        <thead>
                            <tr>
                                {data.columns.map((column, index) => (
                                    <th key={index}>{column}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.rows.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {data.columns.map((column, colIndex) => (
                                        <td key={colIndex}>{row[column]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        }
    }


    return (
        <Collapsable
            title={
                <div className="font-semibold">
                    Action Results
                </div>
            }
        >
            <div className="text-gray-700">
                <p>{toolResults.results}</p>
                <div className="text-xs text-gray-500 mt-1">
                    (This is the result of a tool execution, not the AI's response)
                </div>
            </div>
        </Collapsable>
    );
}


function ChatMessage({ item, editMessage, undoMessage, submitUserInputTool }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editingValue, setEditingValue] = useState(item.content);

    const onEditChange = (e) => {
        setEditingValue(e.target.value);
        item.content = e.target.value;
    }

    const onEditSave = () => {
        editMessage(item.id, {...item, content: editingValue});
        setIsEditing(false);
    }

    const onEditKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onEditSave();
        }
    }

    const onUndo = () => {
        if (window.confirm("Are you sure you want to undo this message? This will load the state of the app before this message.")) {
            undoMessage(item.id);
        }
    }
    
    if (isEditing) {
        return <div className="flex flex-col space-y-2 p-2">
            <ChatTextArea
                value={item.content}
                onChange={onEditChange}
                onKeyDown={onEditKeyDown}
                placeholder="Edit your message..."
            />
            <button 
                onClick={onEditSave}
                className="btn btn-primary btn-sm self-end"
            >
                Edit
            </button>
        </div>
    }
    return (
        <div className='mb-6 relative'>
            <div className="text-sm p-4 bg-gray-100 mx-auto mb-6 rounded-lg flex items-start">
                {item.role === 'user' && (
                    <div className="me-2 mt-1"><UserAvatar userEmail={item.metadata?.created_by_email} userFullName={item.metadata?.created_by_full_name} /></div>
                )}
                <div className="flex-1 pt-2">
                    {item.role === 'tool' ? (
                        item.tool_results.map((toolResults, index) => <ToolResults key={index} toolResults={toolResults} />)
                    ) : (
                        <>
                            <Markdown>{item.content}</Markdown>
                            {item.tool_calls && item.tool_calls.map((toolCall, index) => <ToolCall key={index} toolCall={toolCall} submitUserInputTool={submitUserInputTool} />)}
                        </>
                    )}
                    {item.metadata?.created_date && (
                        <div className="text-xs text-gray-400 mt-2">
                            {moment.utc(item.metadata.created_date, moment.ISO_8601).fromNow()}
                        </div>
                    )}
                </div>
            </div>
            {item.role === "user" && <div className="cursor-pointer absolute bottom-[-10px] right-0 bg-white border border-gray-200 rounded-md p-1 text-xs text-gray-400">
                <a onClick={onUndo} className="">Revert this</a>
            </div>}
        </div>
    );
}

function Chat({chat, addMessage, editMessage, undoMessage, submitUserInputTool, status, onStop}) {
    const [messageContentString, setMessageContentString] = useState('');
    const [fileUrls, setFileUrls] = useState([]);
    const chatContainerRef = useRef(null);

    const handleSend = async () => {
        if (messageContentString.trim() && status.state !== "processing") {
            try {
                const newMessage = {
                    content: messageContentString,
                    file_urls: fileUrls
                }
                
                setMessageContentString('');
                setFileUrls([]);
                await addMessage(newMessage);
            } catch (err) {
                console.error(err);
                toast.error('Failed to send message');
            }
        }
    };

    const handleUndo = (messageId) => {
        const msg = chat.messages.find(m => m.id === messageId);
        if (msg.role === "user") {
            undoMessage(messageId);
            setMessageContentString(msg.content);
        }
    }

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chat.messages?.length]);

    if (!chat) return <div className="alert alert-warning m-4 text-sm">No chat found</div>;

    return (
        <div className="flex flex-col h-full w-full pb-4">
            <div ref={chatContainerRef} className="flex-grow overflow-y-auto py-2 space-y-2">
                {chat.messages.map((item, index) => (
                    <ChatMessage key={index} item={item} editMessage={editMessage} undoMessage={undoMessage ? handleUndo : null} submitUserInputTool={submitUserInputTool} />
                ))}


                {
                    status.state === "error" && <div className="alert alert-error m-4 text-sm">{status.details}</div>
                }
                {
                    status.state === 'processing' && <div className="flex justify-center text-sm py-4">
                        <span className="loading loading-spinner loading-sm me-2"></span>
                        <span>{status.details}</span>
                    </div>
                }
            </div>
            
            <div className="p-2">
                <div className="join w-full">
                    <ChatTextArea
                        value={messageContentString}
                        onValueChange={setMessageContentString}
                        files={fileUrls}
                        onFilesChange={setFileUrls}
                        onSend={handleSend}
                        onStop={onStop}
                        placeholder="How would you like to change the app?"
                        disabled={status.state === "processing"}
                        isProcessing={status.state === "processing"}
                    />
                </div>
            </div>
        </div>
    );
}

export default Chat;