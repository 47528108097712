import moment from 'moment';
import { Calendar } from "@/components/ui/calendar";
import { format as formatDate } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar as CalendarIcon } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

export default function DateInput({ value, onChange, format = "date", className }) {
  const _handleOnChange = (date, time) => {
    if (time) {
      const [hours, minutes] = time.split(':');
      date.setHours(parseInt(hours));
      date.setMinutes(parseInt(minutes));
    }
    onChange(date);
  };

  let parsedValue = value;
  if (value === undefined || value === null || value === '') {
    parsedValue = null;
  } else {
    parsedValue = new Date(value);
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            "w-full justify-start text-left font-normal",
            !parsedValue && "text-muted-foreground",
            className
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {parsedValue ? (
            format === "date" ? 
              formatDate(parsedValue, "PPP") :
              format === "date-time" ?
                formatDate(parsedValue, "PPP HH:mm:ss") :
                formatDate(parsedValue, "HH:mm:ss")
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <div className="p-3">
          <Calendar
            mode="single"
            selected={parsedValue}
            onSelect={(date) => _handleOnChange(date)}
            initialFocus
          />
          {(format === "date-time" || format === "time") && (
            <input
              type="time"
              className="mt-3 w-full px-3 py-2 border rounded"
              value={parsedValue ? formatDate(parsedValue, "HH:mm") : ""}
              onChange={(e) => _handleOnChange(parsedValue || new Date(), e.target.value)}
            />
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}