import React from 'react';
import {
  Select as SelectUI,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command";
import { Badge } from "@/components/ui/badge";
import { X } from "lucide-react";
import { cn } from "@/lib/utils";

const Select = ({ value, onChange, options, isMulti, placeholder, optionsNames = null }) => {
    
    // format options - if option is an object, use the label and value, otherwise use the option
    const formattedOptions = options.map((option, idx) => {
        if (typeof option === 'object') {
            return { label: option.label || optionsNames[idx], value: option.value };
        }
        return { label: optionsNames ? optionsNames[idx] : option, value: option };
    });

    let selectedValues = [];
    if (value) {
        if (isMulti) {
            selectedValues = value.map(v => 
                formattedOptions.find(option => option.value === v)
            ).filter(Boolean);
        } else {
            const found = formattedOptions.find(option => option.value === value);
            if (found) selectedValues = [found];
        }
    }

    const handleSelect = (selectedValue) => {
        if (isMulti) {
            const isSelected = selectedValues.some(v => v.value === selectedValue.value);
            const newValues = isSelected
                ? selectedValues.filter(v => v.value !== selectedValue.value)
                : [...selectedValues, selectedValue];
            onChange(newValues.map(v => v.value));
        } else {
            onChange(selectedValue.value);
        }
    };

    const removeValue = (valueToRemove) => {
        const newValues = selectedValues.filter(v => v.value !== valueToRemove.value);
        onChange(newValues.map(v => v.value));
    };

    if (isMulti) {
        return (
            <Command className="border rounded-md">
                <div className="flex gap-1 flex-wrap p-2">
                    {selectedValues.map((selectedValue) => (
                        <Badge key={selectedValue.value} variant="secondary" className="max-w-[200px]">
                            <span className="truncate">{selectedValue.label}</span>
                            <button
                                className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") removeValue(selectedValue);
                                }}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onClick={() => removeValue(selectedValue)}
                            >
                                <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                            </button>
                        </Badge>
                    ))}
                </div>
                <CommandInput placeholder={placeholder || "Search..."} className="border-none focus:ring-0" />
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup className="max-h-[200px] overflow-auto">
                    {formattedOptions.length === 0 ? (
                        <div className="py-6 text-center text-sm text-muted-foreground">
                            No options available
                        </div>
                    ) : (
                        formattedOptions.map((option) => (
                            <CommandItem
                                key={option.value}
                                onSelect={() => handleSelect(option)}
                                className={cn(
                                    "cursor-pointer",
                                    selectedValues.some(v => v.value === option.value) && "bg-muted"
                                )}
                            >
                                {option.label}
                            </CommandItem>
                        ))
                    )}
                </CommandGroup>
            </Command>
        );
    }

    return (
        <SelectUI value={value?.toString()} onValueChange={(val) => onChange(val)}>
            <SelectTrigger>
                <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {formattedOptions.length === 0 ? (
                        <div className="py-6 text-center text-sm text-muted-foreground">
                            No options available
                        </div>
                    ) : (
                        formattedOptions.map((option) => (
                            <SelectItem key={option.value} value={option.value.toString()}>
                                {option.label}
                            </SelectItem>
                        ))
                    )}
                </SelectGroup>
            </SelectContent>
        </SelectUI>
    );
};

export default Select;
