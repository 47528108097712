import React, { useState } from 'react';
import ComponentPreview from '../../preview/ComponentPreview';
import { useApp } from '../../../context/AppContext';
import AppDemoHeader from './AppDemoHeader';
import {AlertCircle, Loader2} from 'lucide-react'
import JsonSchemaForm from '../../../lib/components/json-schema-form';
import AppCodeEditor from '../AppCodeEditor';
import { DirectionProvider } from "@radix-ui/react-direction";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { Link } from 'react-router-dom';


const AppErrors = ({ appErrors, tryFixError, isFixing }) => {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <div className="flex flex-col items-center w-full max-w-xl mx-auto p-4">
            <div className="bg-white border-2 border-red-300 rounded-lg shadow-lg p-6 mb-4 w-full">
                <div className="flex items-center mb-4">
                    <AlertCircle className="w-8 h-8 text-red-500 mr-3" />
                    <div>
                        <h3 className="text-xl font-bold text-gray-800">Oops! Something went wrong</h3>
                        <p className="text-gray-600">Don't worry, our AI can help fix this issue</p>
                    </div>
                </div>

                <button
                    onClick={() => setShowDetails(!showDetails)}
                    className="w-full mb-4 text-gray-600 hover:text-gray-800 text-sm font-medium py-2 px-4 rounded-md bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
                >
                    {showDetails ? 'Hide Technical Details' : 'Show Technical Details'}
                </button>

                {showDetails && (
                    <div className="bg-gray-50 rounded-md p-4 mb-4">
                        <div className="font-medium text-gray-700 mb-2">Technical Details</div>
                        <div className="space-y-2">
                            {appErrors.map((err, idx) => (
                                <div key={idx} className="text-sm font-mono bg-gray-100 p-3 rounded text-gray-700 whitespace-pre-wrap">
                                    {err}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <button 
                    className="w-full bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 text-white font-medium py-3 px-6 rounded-md transition-all duration-200 flex items-center justify-center"
                    onClick={tryFixError}
                    disabled={isFixing}
                >
                    {isFixing ? (
                        <>
                            <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                            Fixing Issue...
                        </>
                    ) : (
                        'Fix Issue Now'
                    )}
                </button>
            </div>
        </div>
    );
}



function UserDataFormModal() {
    const {app, userInfo, updateUserInfo} = useApp();
    const onSubmit = (userData) => {
        updateUserInfo(userInfo.id, userData);
    }
    return (
        <Dialog open={true}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        Fill in required user data
                    </DialogTitle>
                </DialogHeader>
                <JsonSchemaForm schema={app.additional_user_data_schema} initialValues={userInfo.app_data} onSubmit={onSubmit} />
            </DialogContent>
        </Dialog>
    );
}

export default function AppDemo({setPage}) {
    const { app, entitiesSDK, userInfo, updateUserInfo, integrationsSDK, currentPage, makePageChanges, onAppError, appErrors } = useApp();
    const [isFixing, setIsFixing] = useState(false);
    const [showCodeEditor, setShowCodeEditor] = useState(false);

    function shouldShowUserDataFormModal(app, userInfo) {
        if (!app || !userInfo) {
            return false;
        }
        const requiredFields = app.required_user_data_fields;
        if (!requiredFields || !userInfo) {
            return false;
        }

        const missingFields = requiredFields.filter(field => !userInfo.hasOwnProperty(field) || userInfo[field] === null || userInfo[field] === undefined);
        if (missingFields.length > 0) {
            console.log("user is missing data fields: " + missingFields.join(', ') + " so showing a form to fill it in before showing the app");
        }
        return missingFields.length > 0;
    }

    
    if (shouldShowUserDataFormModal(app, userInfo)) {
        return <UserDataFormModal />;
    }

    const tryFixError = async () => {
        setIsFixing(true);
        const errorAsString = appErrors.length === 1
            ? `the following error happened in the app: ${appErrors[0]}.\n---\nplease help me fix it`
            : `the following errors happened in the app:\n${appErrors.map((error, index) => 
                `Error ${index + 1}: ${error}\n`
              ).join('\n\n')}\n---\nplease help me fix these errors`;
        await makePageChanges(currentPage.id, errorAsString);
        setIsFixing(false);
    }

    const parseUrlParameters = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const params = {};
        urlParams.forEach((value, key) => {
            params[key] = value;
        });
        return params;
    }

    const urlParams = parseUrlParameters();
    const navigateToPage = (page, urlParams) => {
        // check if page is not in app throw error:
        if (!app.pages.some(p => p.id === page || p.name === page)) {
            onAppError(`trying to navigate to a page that does not exist: ${page}`);
            return;
        }
        setPage(page, urlParams);
    }

    let mainContent = null;

    if (!currentPage) {
        mainContent = <div className="alert alert-error">Page not found</div>;
    } else if (currentPage.status?.state === "processing") {
        mainContent = (
            <div className="absolute inset-0 flex flex-col items-center justify-center">
                <span className="loading loading-spinner loading-lg mb-4"></span>
                <div className="text-2xl font-light text-gray-500 mb-2">Processing Page</div>
                <div className="text-sm text-gray-400">{currentPage.status.details}</div>
            </div>
        )
    } else if (!currentPage.code) {
        mainContent = (
            <div className="absolute inset-0 flex flex-col items-center justify-center">
                <div className="text-2xl font-light text-gray-500 mb-2">Page Under Construction</div>
                <div className="text-sm text-gray-400">This page has not been implemented yet</div>
            </div>
        );
    } else if (currentPage.status?.state === "error") {
        mainContent = <div className="alert alert-error">Error: {currentPage.status?.details}</div>;
    } else if (appErrors.length > 0) {
        mainContent = <div className="min-h-full flex flex-col max-w-4xl mx-auto">
            <AppErrors appErrors={appErrors} tryFixError={tryFixError} isFixing={isFixing} />
        </div>
    } else if (integrationsSDK && integrationsSDK.getMissingPackages().length > 0) {
        mainContent = (
            <div className="flex flex-col items-center justify-center p-8 space-y-4 text-center">
                <div className="text-2xl font-light text-gray-600">Additional Integrations Needed</div>
                <div className="text-gray-500">
                    This app requires the following integrations to be connected:
                    <div className="font-medium mt-2">{integrationsSDK.getMissingPackages().join(', ')}</div>
                </div>
                <Link 
                    to="/integrations"
                    className="btn mt-4"
                >
                    Connect Integrations
                </Link>
            </div>
        );
    } else {
        mainContent = <ComponentPreview 
                        componentCode={currentPage.code}
                        componentName={currentPage.name}
                        componentProps={{ 
                            entities: entitiesSDK, 
                            navigateToPage: navigateToPage, 
                            user: userInfo,
                            updateUserData: updateUserInfo,
                            integrations: integrationsSDK,
                            onError:onAppError,
                            urlParams: urlParams
                        }}
                        onError={onAppError}
                    />
    }

    console.log("currentPage", currentPage);

    return (
        <div className="w-full h-full">
            <DirectionProvider dir={app.language_direction}>
                <div className="flex flex-col w-full h-full" id="app-demo" dir={app.language_direction} data-theme={app.theme === 'default' ? null : app.theme}>            
                    <div className="bg-white w-full min-h-full overflow-auto">
                        <AppDemoHeader page={currentPage} extraActions={
                            userInfo.platform_role === "platform_admin" && (
                                <button 
                                    type='button' 
                                    className='btn btn-sm btn-ghost'
                                    onClick={() => setShowCodeEditor(true)}
                                >
                                    see code
                                </button>
                            )
                        } />
                        <div id="component-preview-container">
                            {mainContent}
                        </div>
                    </div>
                </div>
            </DirectionProvider>
        
        {showCodeEditor && (
                <div className="drawer">
                    <input id="code-drawer" type="checkbox" className="drawer-toggle" checked={showCodeEditor} onChange={() => setShowCodeEditor(!showCodeEditor)} />
                    <div className="drawer-side z-50">
                        <label htmlFor="code-drawer" className="drawer-overlay"></label>
                        <div className="w-3/4 h-full bg-base-100">
                            <div className="flex justify-between items-center p-4 border-b">
                                <h3 className="text-lg font-bold">Code Editor</h3>
                                <button className="btn btn-sm btn-ghost" onClick={() => setShowCodeEditor(false)}>✕</button>
                            </div>
                            <div className="h-[calc(100%-4rem)]">
                                <AppCodeEditor />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}