import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react'

export default function Collapsable({title, children}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="w-full max-w-md mx-auto border border-base-300 w-full p-4 bg-gray-50 rounded-lg hover:bg-gray-200 my-2">
      <button
        className="flex justify-between items-center transition-colors duration-200 w-full"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium">{title}</span>
        {isOpen ? (
          <ChevronUp className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-500" />
        )}
      </button>
      {isOpen && <div
        className={`mt-2 p-2 rounded-lg transition-all duration-300 ease-in-out overflow-hidden ${
          isOpen ? 'opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        {children}
      </div>}
    </div>
  )
}