import { BaseAPIProvider } from './base'

class AppLogsAPI extends BaseAPIProvider {
    getBaseURL() {
        return '/app-logs'
    }

    fetchLogs(appId, params = {}) {
        return this.axios.get(`/${appId}`, { params })
    }
}


export default new AppLogsAPI();