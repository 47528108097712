import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Settings, LogOut, Menu, X, MessageSquare } from 'lucide-react';
import logoImage from '../../logo-v1.png';
import {MyUserAvatar} from '../UserAvatar';
import Feedback from './Feedback';


const SwitchEnv = () => {
  const handleSwitchEnv = () => {
    const currentUrl = window.location.href;
    let newUrl;
    
    if (currentUrl.startsWith('https://app.base44.com/')) {
      newUrl = currentUrl.replace('https://app.base44.com/', 'http://localhost:3000/');
    } else if (currentUrl.startsWith('http://localhost:3000/')) {
      newUrl = currentUrl.replace('http://localhost:3000/', 'https://app.base44.com/');
    }

    if (newUrl) {
      window.location.href = newUrl;
    }
  };

  return (
    <div id="switch-env" className="fixed bottom-40 left-7">
      <button 
        onClick={handleSwitchEnv}
        className="tooltip tooltip-right text-white" 
        data-tip="Switch Environment"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"/>
          <path d="M3 3v5h5"/>
          <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16"/>
          <path d="M16 16h5v5"/>
        </svg>
      </button>
    </div>
  );
};

const Sidebar = ({ pages }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, user } = useAuth();
  const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const handleMenuClick = (key) => {
    navigate(key);
    setMobileDrawerVisible(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
    setMobileDrawerVisible(false);
  };

  const menuItems = pages.filter(page => !page.hide).map((page) => ({
    key: page.url,
    label: (
      <div className="flex items-center justify-center w-full h-full">
        <div className="tooltip tooltip-right" data-tip={page.name}>
          {React.cloneElement(page.icon, { size: 24 })}
        </div>
        <span className="ml-3 md:hidden">{page.name}</span>
      </div>
    ),
    onClick: () => handleMenuClick(page.url),
  }));

  const userSubMenuItems = [
    {
      key: 'settings',
      icon: <Settings size={24} />,
      label: 'Settings',
      onClick: () => {
        navigate('/user-settings');
        setMobileDrawerVisible(false);
      },
    },
    {
      key: 'logout',
      icon: <LogOut size={24} />,
      label: 'Sign Out',
      onClick: handleLogout,
    },
  ];

  const SidebarContent = () => (
    <div>
      <div className="h-16 flex justify-center items-center px-4 text-base-200">
        <img 
          src={logoImage} 
          alt="Base44 Logo" 
          className="h-12 w-auto cursor-pointer" 
          onClick={() => navigate('/')}
        />
        <button
          className="btn btn-ghost btn-circle md:hidden absolute right-2"
          onClick={() => setMobileDrawerVisible(false)}
        >
          <X size={24} />
        </button>
      </div>
      <ul className="menu w-full p-2 rounded-box">
        {menuItems.map((item) => (
          <li key={item.key}>
            <a
              className={`flex justify-center md:justify-start py-2 ${
                location.pathname === item.key ? 'active text-base-100' : 'text-base-300'
              }`}
              onClick={item.onClick}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul>
      <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 flex flex-col gap-4 items-center">
        <button 
          className="btn btn-ghost btn-circle"
          onClick={() => setFeedbackOpen(true)}
        >
          <div className="tooltip tooltip-right text-base-200" data-tip="Give Feedback">
            <MessageSquare size={24} />
          </div>
        </button>
        <div className="dropdown dropdown-top">
          <label tabIndex={0} className="btn btn-ghost btn-circle">
            <div className="tooltip tooltip-right" data-tip="User">
              <MyUserAvatar />
            </div>
          </label>
          <ul tabIndex={0} className="mt-3 p-2 shadow menu menu-sm dropdown-content bg-gray-100 rounded-box w-52">
            {userSubMenuItems.map((item) => (
              <li key={item.key}>
                <a onClick={item.onClick}>
                  {item.icon}
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      { user?.platform_role === 'platform_admin' && <SwitchEnv />}
      <Feedback isOpen={feedbackOpen} onClose={() => setFeedbackOpen(false)} />
    </div>
  );

  return (
    <>
      {/* Mobile Menu Button */}
      <button
        className="btn btn-square btn-ghost fixed top-4 left-4 z-50 md:hidden"
        onClick={() => setMobileDrawerVisible(true)}
      >
        <Menu size={24} />
      </button>

      {/* Overlay */}
      {mobileDrawerVisible && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden" 
          onClick={() => setMobileDrawerVisible(false)}
        ></div>
      )}

      {/* Mobile Drawer */}
      <div className={`fixed inset-y-0 left-0 z-50 w-64 transform ${mobileDrawerVisible ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:hidden shadow-lg`}>
        <SidebarContent />
      </div>

      {/* Desktop Sidebar */}
      <div className="hidden z-[2] md:block w-20 h-full relative shadow-lg bg-neutral">
        <SidebarContent />
      </div>
    </>
  );
};

export default Sidebar;
