import { BaseAPIProvider, BaseAPIModelProvider } from './base'

class _InstallableIntegrationsAPI extends BaseAPIModelProvider {
    getBaseURL() {
        return '/integrations/installable'
    }

    listIntegrations() {
        return this.axios.get('/schemas')
    }

    getIntegrationAPI(integrationName) {
        class IntegrationAPI extends BaseAPIModelProvider {
            getBaseURL() {
                return `/integrations/installable/${integrationName}`
            }

            test(integrationProps) {
                return this.axios.post(`/test`, integrationProps)
            }
        }
        return new IntegrationAPI();
    }

    
}


const InstallableIntegrationsAPI = new _InstallableIntegrationsAPI();

export default InstallableIntegrationsAPI;