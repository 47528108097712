import { useEffect } from "react"
import ToggleWithText from "../../components/ToggleWithText"
import ThemeSelector from "../../components/ThemeSelector"
import AppDemo from "../../components/user-app/demo/AppDemo"
import EditorWorkspace from "./EditorWorkspace"
import { useApp } from '../../context/AppContext';
import { useNavigate, Link } from 'react-router-dom';
import { MoreVertical } from 'lucide-react';
import { useMediaQuery } from 'react-responsive';
import { useTopbar } from "../../components/layout/MobileTopBar"
import AppProductFlows from "./editor-subpages/AppProductFlows"

export default function EditorMain({view}) {
    const { app } = useApp();
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { setCenterContent, setRightContent } = useTopbar();

    // if (app.app_stage === "product_flows") {
    //     return <AppProductFlows />
    // }

    const handleViewChange = (view) => {
        console.log("switing to", view);
        if (view.toLowerCase() === "workspace") {
            navigate(`/apps/${app.id}/editor/workspace/overview`);
        } else {
            navigate(`/apps/${app.id}/editor/preview/${app.pages[0].name}`);
        }
    }

    const ToggleElement = <ToggleWithText option1="Workspace" option2="Preview" selected={view === 'preview' ? 'Preview' : 'Workspace'} onChange={handleViewChange} />

    useEffect(() => {
        setCenterContent(ToggleElement);
        setRightContent(<div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost btn-sm">
                <MoreVertical size={20} />
            </label>
            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-gray-50 rounded-box w-52">
                {/* <li><ThemeSelector updateApp={updateApp} /></li> */}
                <li><Link to={`/apps/${app.id}/show`} target="_blank">Publish</Link></li>
            </ul>
        </div>);
        return () => {
            setCenterContent(null);
            setRightContent(null);
        }
    }, [isMobile, view]);

    const setCurrentPage = (pageName, urlParams) => {
        navigate(`/apps/${app.id}/editor/preview/${pageName}?${new URLSearchParams(urlParams).toString()}`);
    }

    if (isMobile) {
        return <div className="flex-grow h-full overflow-y-auto">
            {view === "preview" ? <AppDemo setPage={setCurrentPage} /> : <EditorWorkspace />}
        </div>
    }

    return <div className="flex-grow h-full overflow-y-auto p-4">
        <div className={`mockup-browser bg-gray-100 border-gray-200 border h-full shadow flex flex-col ${isMobile && '!rounded-none'}`}>
            <div className="mockup-browser-toolbar flex justify-between items-center" style={{margin: "6px 0px"}}>
                {ToggleElement}
                <div>
                    {/* <ThemeSelector updateApp={updateApp} /> */}
                    <Link to={`/apps/${app.id}/show`} target="_blank" className="btn btn-neutral btn-sm">Publish</Link>
                </div>
            </div>
            <div className="border-gray-200 flex justify-center border-t w-full overflow-y-auto flex-grow relative">
                {view === "preview" ? <AppDemo setPage={setCurrentPage} /> : <EditorWorkspace />}
            </div>
        </div>    
    </div>
}