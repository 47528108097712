import { useState } from 'react';
import { X } from 'lucide-react';
import FeedbackAPI from '../../api/FeedbackAPI';
import { toast } from 'react-hot-toast';

export default function Feedback({ isOpen, onClose }) {
  const [emoji, setEmoji] = useState(null);
  const [feedback, setFeedback] = useState('');

  const emojis = [
    { value: '🤮', label: 'Terrible' },
    { value: '🙁', label: 'Bad' }, 
    { value: '😐', label: 'Okay' },
    { value: '🙂', label: 'Good' },
    { value: '😍', label: 'Amazing' }
  ];

  const handleSubmit = async () => {
    try {
      await FeedbackAPI.create({
        emoji: emoji,
        feedback: feedback
      });
      toast.success('Feedback submitted successfully');
      onClose();
      setEmoji(null);
      setFeedback('');
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-100 rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">Submit Feedback</h2>
          <button onClick={onClose} className="btn btn-ghost btn-sm">
            <X size={20} />
          </button>
        </div>

        <div className="mb-6">
          <p className="mb-3">How was your experience?</p>
          <div className="flex justify-between gap-2">
            {emojis.map((emojiOption) => (
              <button
                key={emojiOption.value}
                onClick={() => setEmoji(emojiOption.value)}
                className={`btn btn-ghost text-2xl ${emoji === emojiOption.value ? 'bg-base-200' : ''}`}
                title={emojiOption.label}
              >
                {emojiOption.value}
              </button>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <p className="mb-3">Give us more details</p>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            className="textarea textarea-bordered w-full h-32"
            placeholder="Tell us what you think..."
          />
        </div>

        <div className="flex flex-col gap-4">
          <button
            onClick={handleSubmit}
            disabled={!emoji || !feedback}
            className="btn btn-primary w-full"
          >
            Submit
          </button>
          
          <p className="text-sm text-center">
            Need help instead? email us at <a href="mailto:app@base44.com" className="link">app@base44.com</a>
          </p>
        </div>
      </div>
    </div>
  );
}
