import React from 'react';
import { Checkbox as CheckboxUI } from "@/components/ui/checkbox";

const Checkbox = ({ value, onChange }) => {
  return (
    <CheckboxUI
      checked={value}
      onCheckedChange={onChange}
    />
  );
};

export default Checkbox;
