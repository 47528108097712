import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center min-h-screen bg-base-200">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-error">404</h1>
        <p className="text-2xl mt-4 mb-8">Sorry, the page you visited does not exist.</p>
        <button 
          className="btn btn-primary"
          onClick={() => navigate('/')}
        >
          Back Home
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
