import React from 'react';

const SidebarItem = ({ item, selected, onSelect }) => {
  if (item.subitems) {
    return (
      <li>
        <a>
          {item.icon} {item.label}
        </a>
        <ul>
          {item.subitems.map((subitem, index) => (
            <li key={index}>
              <a
                className={selected === subitem.id ? 'bg-gray-200' : ''}
                onClick={() => onSelect(subitem.id)}
              >
                {subitem.icon} {subitem.label}
              </a>
            </li>
          ))}
        </ul>
      </li>
    );
  }

  return (
    <li>
      <a
        className={selected === item.id ? 'bg-gray-200' : ''}
        onClick={() => onSelect(item.id)}
      >
        {item.icon} {item.label}
      </a>
    </li>
  );
};

const EditorSidebar = ({ items, selected, onSelect, isOpen, onClose }) => {
  return (
    <>
      {/* Mobile overlay */}
      <div 
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity lg:hidden ${
          isOpen ? 'opacity-100 z-40' : 'opacity-0 pointer-events-none'
        }`}
        onClick={onClose}
      />

      {/* Sidebar */}
      <div
        className={`fixed lg:static inset-y-0 left-0 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 transition duration-200 ease-in-out w-56 bg-gray-100 h-full overflow-y-auto border-r z-50 lg:z-0`}
      >
        <div className="flex justify-between items-center p-4 lg:hidden">
          <h2 className="font-semibold">Menu</h2>
          <button onClick={onClose} className="p-2">✕</button>
        </div>
        <ul className="menu">
          {items.map((item, index) => (
            <SidebarItem key={index} item={item} selected={selected} onSelect={onSelect} />
          ))}
        </ul>
      </div>
    </>
  );
};

export default EditorSidebar;
